import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Header from '../../../components/header/Header'
// import model404 from '../../../assets/img/brand/model404.jpg'

class Page404 extends Component {
  render() {
    return (
      <Fragment>
        <div>
          <Header />
        </div>
        {/* <div>
          <Header />
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col>
                    <Label>Oops! esta pagina no existe</Label>
                  </Col>
                </Row>

                <Row className="mt-3 text-center">
                  <Col sm={12}>
                    Si quieres regresar a nuestra plataforma ingresa a este link
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={6} className="text-center">
              </Col>
            </Row>
          </Container>
        </div> */}

        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <div className="clearfix">
                  <h1 className="float-left display-3 mr-4">404</h1>
                  <h4 className="pt-3">Oops! esta pagina no existe.</h4>
                  {/* <p className="text-muted float-left">The page you are looking for was not found.</p> */}
                </div>
                
                <div>
                  
                </div>
                {/* <InputGroup className="input-prepend">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input size="16" type="text" placeholder="What are you looking for?" />
                  <InputGroupAddon addonType="append">
                    <Button color="info">Search</Button>
                  </InputGroupAddon>
                </InputGroup> */}
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default Page404;
