import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DefaultLayout from './containers/DefaultLayout';
import { 
  Page404, 
  Page500, 
  Register, 
  Login, 
  RegisterFast, 
  // EventPublic,
  ActivateAccount,
  ForgotPassword,
  ConfirmEmail,
  ShowTalentEvent,
  Contract,
  Cabify
 } from './views/Pages';
import Talent from './views/Talent/Talent';
import './App.scss';

const App = () => {
  console.warn = () => {};
  //TODO: Coloca el header en todos las paginas
  return (
    <Router>
      <Switch>
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <Route exact path="/registro" name="Register" component={Register} />
        <Route exact path="/login" name="Login" component={Login} />
        {/* <Route exact path={`/agencias/:nameAgency/:nameEvent`} name="EventoPublico" component={EventPublic} /> */}
        <Route exact path="/registrofast" name="Registro" component={RegisterFast} />
        <Route exact path="/olvidaste-contrasena" name="Olvidaste Contraseña" component={ForgotPassword} />
        <Route exact path="/activar-cuenta/:id/:type" name="Activar Cuenta" component={ActivateAccount} />
        <Route exact path="/restablecer-contrasena/:id/:type/:email?" name="Restablecer Contraseña" component={ActivateAccount} />
        <Route exact path="/confirm-email" name="Confirmar Correo" component={ConfirmEmail} />
        <Route path="/evento-cliente/:id_event" name="ShowTalentEvent" component={ShowTalentEvent} />
        <Route path="/talento/:id_event/:idTalent" name="ShowTalentEvent" component={Talent} />
        <Route exact path="/contract-talent/:idEvent/:idTalent" name="Contract" component={Contract} />
        <Route exact path="/transportation-service/:idConvocatory/:idTalent" name="Cabify" component={Cabify} />
        <Route path="/" name="Home" component={DefaultLayout} />
      </Switch>
    </Router>
  );
}

export default App;