import React, { Component, Fragment } from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router'
import * as Yup from 'yup';
import { Alert, Button, Card, CardBody, Container, Col, CustomInput, Form, Row, Label } from 'reactstrap';
import Header from '../../../components/header/Header';
//import Agency from './components/Agency';
import FormRegisterFastView from './components/FormRegisterFast/FormRegisterFastView';
import { AppFooter } from '@coreui/react';
import DefaultFooter from '../../../containers/DefaultLayout/DefaultFooter';
import './RegisterFast';

class RegisterFastView extends Component {
  state = {
    error: undefined,
  }

  render() {
    const {
      signUp,
      messageError,
      messageSuccess,
      changeStateError,
      countries,
      changeCodeCountry,
      cities,
      handleCitiesAndTypes,
      codeCountry,
      types,
      isLegalPerson,
      changeLegalNature,
      resetCodeCountry,
      countriesModAgency,
      citiesModAgency,
      typesModAgency,
      codeCountryModAgency,
    } = this.props;

    const validations = {
      name: Yup.string().required('Por favor llene este campo'),
      lastname: Yup.string().required('Por favor llene este campo'),
      email: Yup.string().email('Este email no es correcto').required('Por favor llene este campo'),
      phone: Yup.string().length(14, 'Telefono incorrecto').required('Por favor llene este campo'),
      country: Yup.string().required('Por favor llene este campo'),
      city: Yup.string().required('Por favor llene este campo'),
      typeIdentification: Yup.string().required('Por favor llene este campo'),
      identification: Yup.string().required('Por favor llene exste campo'),
    }

    const validationsAgency = {
      name: Yup.string().required('Por favor llene este campo'),
      country: Yup.string().required('Por favor llene este campo'),
      city: Yup.string().required('Por favor llene este campo'),
      typeIdentification: Yup.string().required('Por favor llene este campo'),
      identification: Yup.string().required('Por favor llene este campo'),
    }

    const defaultValues = {
      name: '',
      lastname: '',
      email: '',
      phone: '',
      country: '',
      city: '',
      typeIdentification: '',
      identification: '',
    }

    return (
      <Fragment>
        <div className="app">
          <div className="mw-100">
            <Header />
          </div>

          <Row className='body-container'>
            <Col md={12} className='no-padding'>
              <Formik
                initialValues={{
                  person: 'naturalPerson',
                  producer: { ...defaultValues },
                  agency: { ...defaultValues },
                }}

                validationSchema={Yup.object().shape({
                  person: Yup.string().required('Por favor llene este campo'),
                  producer: Yup.object().shape({
                    ...validations
                  }),
                  agency: !isLegalPerson ? '' : Yup.object().shape({
                    ...validationsAgency
                  })
                })}

                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  const { history } = this.props
                  try {
                    await signUp(values)

                    history.push('/confirm-email')
                  } catch (error) {

                  }
                  setSubmitting(false)
                }} >

                {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, handleReset, setFieldValue, dirty, resetForm, setValues }) => (
                  <Form onSubmit={handleSubmit} className="login" >
                    <Row style={{ width: '100%' }}>

                      <Col md={7} lg={8} className='no-padding imgcontainer'>
                        <div className="register-image"></div>
                      </Col>

                      <Col md={5} lg={4}>
                        <Row className="text-center top">
                          <Col>
                            <Label style={{ fontSize: "18px" }}><strong>Registro para agencias</strong></Label>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={6} sm={12} className="text-center">
                            <CustomInput type="radio" id="naturalPerson" name="person" label="Soy persona" checked={values.person === 'naturalPerson'} onChange={e => {
                              changeLegalNature()
                              setFieldValue('person', e.target.id)
                            }} />
                          </Col>

                          <Col lg={6} sm={12} className="text-center">
                            <CustomInput type="radio" id="legalPerson" name="person" label="Soy empresa" checked={values.person === 'legalPerson'} onChange={e => {
                              changeLegalNature()
                              setFieldValue('person', e.target.id)
                            }} />
                          </Col>
                        </Row>

                        {values.person === 'legalPerson' &&
                          <Row className={`animated bounceInDown`} >
                            <Col sm={12}>
                              <FormRegisterFastView
                                values={{
                                  name: values.agency.name,
                                  email: values.agency.email,
                                  phone: values.agency.phone,
                                  country: values.agency.country,
                                  city: values.agency.city,
                                  typeIdentification: values.agency.typeIdentification,
                                  identification: values.agency.identification,
                                }}

                                identifiers={{
                                  name: "agency.name",
                                  email: "agency.email",
                                  phone: "agency.phone",
                                  country: "agency.country",
                                  city: "agency.city",
                                  typeIdentification: "agency.typeIdentification",
                                  identification: "agency.identification"
                                }}

                                title="agencia"
                                cities={citiesModAgency}
                                handleCitiesAndTypes={handleCitiesAndTypes}
                                types={typesModAgency}
                                codeCountry={codeCountryModAgency}
                                countries={countriesModAgency}
                                changeStateError={changeStateError}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                changeCodeCountry={changeCodeCountry} />

                              {/* title="productor"
                            cities={cities}
                            handleCitiesAndTypes={handleCitiesAndTypes}
                            types={types}
                            codeCountry={codeCountry}
                            countries={countries}
                            changeStateError={changeStateError}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            changeCodeCountry={changeCodeCountry} */}
                            </Col>
                          </Row>
                        }

                        <Row>
                          <Col sm={12}>
                            <FormRegisterFastView
                              values={{
                                name: values.producer.name,
                                lastname: values.producer.lastname,
                                email: values.producer.email,
                                phone: values.producer.phone,
                                country: values.producer.country,
                                city: values.producer.city,
                                typeIdentification: values.producer.typeIdentification,
                                identification: values.producer.identification,
                              }}

                              identifiers={{
                                name: "producer.name",
                                lastname: "producer.lastname",
                                email: "producer.email",
                                phone: "producer.phone",
                                country: "producer.country",
                                city: "producer.city",
                                typeIdentification: "producer.typeIdentification",
                                identification: "producer.identification"
                              }}

                              title="productor"
                              cities={cities}
                              handleCitiesAndTypes={handleCitiesAndTypes}
                              types={types}
                              codeCountry={codeCountry}
                              countries={countries}
                              changeStateError={changeStateError}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              changeCodeCountry={changeCodeCountry} />
                          </Col>
                        </Row>

                        <div className="mt-4" style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            type="submit"
                            color="success"
                            disabled={!dirty || isSubmitting}
                            style={{ cursor: !dirty || isSubmitting ? 'not-allowed' : 'pointer' }} >
                            <i className="icon-user"></i>
                            &nbsp;{isSubmitting ? 'Cargando...' : 'Registrate'}
                          </Button>

                          <Button
                            type="button"
                            color="info"
                            className="ml-2"
                            disabled={!dirty}
                            onClick={() => {
                              resetCodeCountry();
                              const changes = {
                                person: values.person,
                                producer: { ...defaultValues },
                                agency: { ...defaultValues }
                              }

                              resetForm(changes)
                            }} >
                            <i className="icon-user"></i> &nbsp; Borrar
                          </Button>
                        </div>

                        {
                          messageError &&
                          <Row className="mt-3 text-center" >
                            <Col>
                              <Alert color="danger">
                                {messageError}
                              </Alert>
                            </Col>
                          </Row>
                        }

                        {
                          messageSuccess &&
                          <Row className="mt-3 text-center">
                            <Col>
                              <Alert color="success">
                                {messageSuccess}
                              </Alert>
                            </Col>
                          </Row>
                        }
                      </Col>
                    </Row>

                  </Form>
                )}
              </Formik>
            </Col>
          </Row>

        </div>
      </Fragment >
    )
  }
}

export default withRouter(RegisterFastView);
