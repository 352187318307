import React, { Component, Fragment } from 'react';
import ShowTalentEventView from './ShowTalentEventView';
import { withApollo } from 'react-apollo';
import { GET_EVENT_BY_ID, getProfilesTalent } from '../../../controllers/graphql/queries';

class ShowTalentEvent extends Component {

    state = {
        loading: true,
        eventValid: false,
        event: {},
        talentList: {}
    }

    getStates = async () => {
        const { client } = this.props;
        const { data } = await client.query({ query: getProfilesTalent });
        
        localStorage.setItem('states', JSON.stringify({
          states: {
            profileTalent: data.profileTalent.list,
          }
        }));
    }
    componentWillMount = async () => {
        const { match, client } = this.props;
        
        const { data } = await client.query({
            query: GET_EVENT_BY_ID,
            variables: { id: match.params.id_event },
            fetchPolicy: 'network-only'
        })

        var invalidStates = [36, 37, 38];

        if (!invalidStates.includes(data.event.stateId)) {
            //el evento es valido
            let talentList = {};

            if (data.event.announcements.length !== 0) {
                talentList = await this.calculateTalentList(data.event.announcements);
            }
            await this.getStates();
            this.setState({
                eventValid: true,
                event: data.event,
                talentList
            });
        }

        this.setState({
            loading: false
        })
    }

    calculateTalentList(announcements) {
        const newTalentList = [];
        const talentIdSet = new Set(); // Conjunto para almacenar IDs de talentos

        announcements.list.forEach(convocated => {
            convocated.convocateds.list.forEach(dato => {
                const talentId = dato.talent.id;
                // Verificar si el ID ya está en el conjunto
                if (!talentIdSet.has(talentId)) { //el has retorna true si el id se encuentra en el conjunto de datos
                    newTalentList.push({
                        talent: dato.talent, 
                        stateId: dato.stateId,
                        aplicationTalentState: dato.aplicationTalentState
                    });
                    talentIdSet.add(talentId); // Agregar el ID al conjunto
                }
            });
        });
        return newTalentList;
    }

    openAditionalProfile = () => {
        const { event } = this.state;
        window.open(`${event.additionalProfiles}`, '_blank');
    }

    openProfile = async (idTalent) => {
        const { match } = this.props;
        const idEvent = match.params.id_event
        const url = `${process.env.REACT_APP_HOST_WEB_APP}/talento/${idEvent}/${idTalent}`;
        window.open(url, '_self');
    }

    render() {
        const { loading, eventValid, event, talentList } = this.state
        return (
            <Fragment>
                <ShowTalentEventView
                    loading={loading}
                    eventValid={eventValid}
                    event={event}
                    talentList={talentList}
                    openAditionalProfile={this.openAditionalProfile}
                    openProfile={this.openProfile}
                />
            </Fragment>
        );
    }
}

export default withApollo(
    ShowTalentEvent
);