import React, { Component, Fragment } from 'react';
import { Col, Row, Button, Modal, ModalBody, ModalHeader, ModalFooter, Label, FormGroup, Input } from 'reactstrap';

class ModalDeletePhoto extends Component {
    state = {
        selectedOption: ''
    };

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
    };

    render() {
        const { handleRechazar, isOpen, handleCloseModal, indexSelect } = this.props;
        const { selectedOption } = this.state;

        return (
            <Fragment>
                <Modal isOpen={isOpen}>
                    <ModalHeader>
                        ¿Desea rechazar la imagen?
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup tag="fieldset">
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="option"
                                        value="No se aceptan selfies."
                                        checked={selectedOption === 'No se aceptan selfies.'}
                                        onChange={this.handleOptionChange}
                                    />
                                    No se aceptan selfies.
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="option"
                                        value="No es fondo blanco (puedes cargarla en la sección de Galería de Eventos)."
                                        checked={selectedOption === 'No es fondo blanco (puedes cargarla en la sección de Galería de Eventos).'}
                                        onChange={this.handleOptionChange}
                                    />
                                    No es fondo blanco (puedes cargarla en la sección de Galería de Eventos).
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="option"
                                        value="Mala calidad."
                                        checked={selectedOption === 'Mala calidad.'}
                                        onChange={this.handleOptionChange}
                                    />
                                    Mala calidad.
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="option"
                                        value="No se aceptan screenshots."
                                        checked={selectedOption === 'No se aceptan screenshots.'}
                                        onChange={this.handleOptionChange}
                                    />
                                    No se aceptan screenshots.
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="option"
                                        value="Foto repetida."
                                        checked={selectedOption === 'Foto repetida.'}
                                        onChange={this.handleOptionChange}
                                    />
                                    Foto repetida.
                                </Label>
                            </FormGroup>
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter style={{display: 'flex !impotant' ,alignItems: 'center !important'}}>
                        <Button
                            color="secondary"
                            onClick={handleCloseModal}
                            style={{ cursor: 'pointer' }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            color={'success'}
                            style={{ cursor: !selectedOption ? 'not-allowed' : 'pointer' }}
                            disabled={!selectedOption}
                            onClick={() => handleRechazar(selectedOption, indexSelect)}
                        >
                            Aceptar
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

export default ModalDeletePhoto;
