import React, { Component, Fragment } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import { registerFast } from '../../../controllers/graphql/mutation';
import { getParameters, getStatesAgencyUser } from '../../../controllers/graphql/queries';
import ServiceInteractor from '../../../controllers/services/ServiceInteractor';
import RegisterFastView from './RegisterFastView';
import './RegisterFast.css';

const Interactor = new ServiceInteractor();

class RegisterFast extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popupMessageCreated: false,
      messagePhone: false,
      messageError: false,
      messageSuccess: false,
      codeCountry: '',
      countries: [],
      cities: [],
      types: [],
      isLegalPerson: false,
      countriesModAgency: [],
      citiesModAgency: [],
      typesModAgency: [],
      codeCountryModAgency: ''
    }
  }

  componentDidMount() {
    this.getParameters()
  }

  getParameters = async () => {
    const { client } = this.props
    try {
      if (!localStorage.getItem('parameters')) {
        const response = await client.query({ query: getParameters })
        localStorage.setItem('parameters', JSON.stringify({
          countries: response.data.countries.list
        }))

        this.setState({
          countries: response.data.countries.list,
          countriesModAgency: response.data.countries.list,
        })
      } else {
        const data = JSON.parse(localStorage.getItem('parameters'))
        this.setState({
          countries: data.countries,
          countriesModAgency: data.countries
        })
      }

      //TODO: Consumir servicios sin localStorage
      if (!localStorage.getItem('states')) {
        const responseStates = await client.query({ query: getStatesAgencyUser })

        localStorage.setItem('states', JSON.stringify({
          states: {
            agency: responseStates.data.statesAgency.list,
            user: responseStates.data.statesUser.list,
          }
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  changeStateError = () => this.setState({ messageError: false })

  changeCodeCountry = (code, title) => {
    const codigo = !code ? '' : code.toLowerCase()
    if (title === 'productor') {
      this.setState({
        codeCountry: codigo,
        messageError: false
      })
    } else {
      this.setState({
        codeCountryModAgency: codigo,
        messageError: false
      })
    }
  }

  changeLegalNature = () => this.setState({ isLegalPerson: !this.state.isLegalPerson })

  resetCodeCountry = () => this.setState({ codeCountry: '' })

  handleCitiesAndTypes = (id, title) => {
    let elements = [];

    if (id !== '') {
      const { countries } = this.state
      const index = Number(id);
      elements = countries.filter(country => country.id === index);
    }

    if (title === 'productor') {
      this.setState({
        cities: id !== '' ? elements[0].cities.list : [],
        types: id !== '' ? elements[0].typeDocuments.list : []
      })
    } else {
      this.setState({
        citiesModAgency: id !== '' ? elements[0].cities.list : [],
        typesModAgency: id !== '' ? elements[0].typeDocuments.list : []
      })
    }
  }

  dataToSend = (person, agency, producer) => {
    const { states } = JSON.parse(localStorage.getItem('states'))

    const legalNature = person === 'naturalPerson' ? true : false

    return {
      nameUser: producer.name,
      lastnameUser: producer.lastname,
      cityUser: Number(producer.city),
      typeIdUser: Number(producer.typeIdentification),
      identificationUser: producer.identification.trim(),
      phoneUser: producer.phone.replace(/[()' '-]/g, ''),
      emailUser: producer.email.trim(),
      stateUser: states.user[1].id,
      nameAgency: legalNature ? `${producer.name.trim()} ${producer.lastname.trim()}` : agency.name.trim(),
      cityAgency: legalNature ? Number(producer.city) : Number(agency.city),
      typeIdAgency: legalNature ? Number(producer.typeIdentification) : Number(agency.typeIdentification),
      identificationAgency: legalNature ? producer.identification.trim() : agency.identification.trim(),
      phoneAgency: producer.phone.replace(/[()' '-]/g, ''),
      emailAgency: producer.email.trim(),
      stateAgency: states.agency[1].id,
      legalNature: legalNature ? "PERSONA_NATURAL" : "PERSONA_JURIDICA"
    }
  }

  dataForEmail = (person, agency, producer) => {
    const parameters = JSON.parse(localStorage.getItem('parameters')).countries
    const countrySelected = parameters.find(item => item.id === Number(producer.country))

    let object = {
      person: person === 'naturalPerson' ? 'Persona' : 'Comercio',
      producer: {
        name: producer.name,
        lastname: producer.lastname,
        country: countrySelected.name,
        city: countrySelected.cities.list.find(city => city.id === Number(producer.city)).name,
        typeIdentification: countrySelected.typeDocuments.list.find(type => type.id === Number(producer.typeIdentification)).name,
        identification: producer.identification,
        email: producer.email,
        phone: producer.phone.replace(/[()' '-]/g, '')
      },
      agency: null
    }

    if (person !== 'naturalPerson') {
      const countryAgency = parameters.find(item => item.id === Number(agency.country))
      object.agency = {
        name: agency.name,
        country: countryAgency.name,
        city: countryAgency.cities.list.find(city => city.id === Number(agency.city)).name,
        typeIdentification: countryAgency.typeDocuments.list.find(type => type.id === Number(agency.typeIdentification)).name,
        identification: agency.identification,
        email: producer.email,
        phone: producer.phone.replace(/[()' '-]/g, '')
      }
    }

    return object
  }

  signUp = (values) => {
    const { person, agency, producer } = values;
    const { register } = this.props;
    // let taxationByCityForCreate = [], newTaxation = [];
    let object = this.dataToSend(person, agency, producer)
    let data = this.dataForEmail(person, agency, producer)

    return new Promise(async (resolve, reject) => {
      try {
        const response = await register({ variables: object })
        const responseIdUser = response.data.register.user.id;
        // const responseIdAgency = response.data.register.agency.id;
        // const idCountry = person === 'naturalPerson' ? Number(producer.country) : Number(agency.country);
        // const idCity = person === 'naturalPerson' ? Number(producer.city) : Number(agency.city);
        // const responseTaxations = await client.query({
        //   query: GET_TAXATION_BY_REGISTER,
        //   variables: { idCountry }
        // })
        // const taxations = responseTaxations.data.taxations.list;
        // console.log("al que le hacemos el map:", taxations);

        // taxations.map(taxation => {
        //   const regionalEntity = taxation.regionalEntity ? taxation.regionalEntity.toLowerCase() : null
        //   const idPriceTaxation = taxation.pricesTaxations.list[0].id
        //   console.log("iteracion");
        //   if (regionalEntity === 'local') {
        //     taxation.pricesTaxations.list.map(item => {
        //       if (item.cityId !== null && idCity !== item.cityId) {
        //         let object = {
        //           cityId: idCity,
        //           taxationId: taxation.id
        //         }
        //         taxationByCityForCreate.push(object)
        //       } else if (idCity === item.cityId ) {
        //         console.log("entra else if city");
        //         console.log(idCity);
        //         newTaxation.push({ idPriceTaxation });
        //         console.log(newTaxation);
        //       }
        //     })
        //   } else {
        //     const idPriceTaxation = taxation.pricesTaxations.list[0].id
        //     newTaxation.push({ idPriceTaxation })
        //     console.log("entra al else");
        //     console.log(newTaxation);
        //   }
        // } )
        
        // if (taxationByCityForCreate.length !== 0) {
        //   const mutation = handleCreatePriceTaxation(idCity, taxationByCityForCreate) // generar una consulta GraphQL que crea múltiples instancias de PricesTaxation asociadas a una ciudad 
        //   const { body } = mutation.loc.source;
        //   const firstMutation = body.split(',')[0] + '}';
        //   const responsePrices = await client.mutate({ mutation: gql(firstMutation) });
        //   for (const key in responsePrices.data) {
        //     const info = responsePrices.data[key]
        //     newTaxation.push({ idPriceTaxation: info.pricesTaxation.id })
        //   }
        // }

        // const mutation = handleCreateTaxationAgency(responseIdAgency, newTaxation); //crea múltiples instancias de AgencyPricesTaxation asociadas a una agencia específica y a diferentes impuestos de precios
        // console.log("paso");
        // console.log(newTaxation); //esto es absurdamente grande
        // const responsePricesTaxation = await client.mutate({ mutation }); //aqui se queda 2 minutos

        this.setState({ messageSuccess: 'Registro Completado' })
        resolve(response)
        data.producer.id = responseIdUser;
        await Interactor.postNotifyRegisterFast(data)
      } catch (error) {
        console.log(error)
        this.handleErrors(error, person)
        reject(error)
      }
    })
  }

  handleErrors = (error, person) => {
    if (error.hasOwnProperty('graphQLErrors')) {
      if (error.graphQLErrors[0].message.includes('email')) {
        this.setState({ messageError: 'Correo electronico del productor ya existe' })
      } else if (error.graphQLErrors[0].message.includes('identification')) {
        this.setState({ messageError: 'Numero de identificacion del productor ya existe' })
      }
      // else if (error.graphQLErrors[0].message.includes('agency_name')) {
      //   this.setState({ 
      //     messageError: person === 'naturalPerson' ? 'Este productor ya existe por favor comuniquese con soporte Italentt' : 'Esta agencia ya existe por favor comunicate a soporte al celular ' 
      //   })
    } else {
      console.log(error)
      this.setState({ messageError: 'Por favor intente más tarde' })
    }
  }

  render() {
    const {
      messageError,
      messageSuccess,
      countries,
      cities,
      codeCountry,
      types,
      isLegalPerson,
      countriesModAgency,
      typesModAgency,
      citiesModAgency,
      codeCountryModAgency
    } = this.state;

    return (
      <Fragment>
        <RegisterFastView
          signUp={this.signUp}
          messageError={messageError}
          messageSuccess={messageSuccess}
          changeStateError={this.changeStateError}
          changeCodeCountry={this.changeCodeCountry}
          countries={countries}
          handleCitiesAndTypes={this.handleCitiesAndTypes}
          cities={cities}
          codeCountry={codeCountry}
          types={types}
          isLegalPerson={isLegalPerson}
          changeLegalNature={this.changeLegalNature}
          countriesModAgency={countriesModAgency}
          citiesModAgency={citiesModAgency}
          typesModAgency={typesModAgency}
          codeCountryModAgency={codeCountryModAgency}
          resetCodeCountry={this.resetCodeCountry} />
      </Fragment>
    )
  }
}

export default compose(
  withApollo,
  graphql(registerFast, { name: "register" }),
)(RegisterFast);

/*
catch (error) {
        console.log(error)
        console.log(error.graphQLErrors)
        this.handleErrors(error)
        /*if (error.hasOwnProperty('graphQLErrors')) {
          if (error.graphQLErrors[0].message.includes('email')) {
            console.log(error.graphQLErrors[0].message)
            this.setState({ messageError: 'Correo electronico productor ya existe' })
          } else if (error.graphQLErrors[0].message.includes('agency_name')) {
            console.log(error.graphQLErrors[0].message)
            this.setState({ messageError: 'Esta agencia ya existe' })
          } else if (error.graphQLErrors[0].message.includes('identification')) {
            console.log(error.graphQLErrors[0].message)
            this.setState({ messageError: 'Numero de identificacion ya existe' })
          }
        } else {
          console.log(error)
          this.setState({ messageError: 'Por favor intente más tarde' })
        }
        reject(error)
      }*/

/*let typeIdentification, phone;
    const { createAgency } = this.props;
    const { agency, producer } = values;

    typeIdentification = agency.typeIdentification.replace(/[.' ']/g, '_').toUpperCase();
    phone = producer.phone.replace(/[()' '-]/g, '')

    return new Promise(async (resolve, reject) => {
      try {
        const response = await createAgency({
          variables: {
            nameAgency: agency.name,
            phoneAgency: agency.phone,
            typeIdentification: typeIdentification,
            identification: agency.identification,
            stateAgency: "REVISION",
            nameUser: producer.name,
            email: producer.email,
            phoneUser: producer.phone,
            stateUser: "CREADO"
          }
        })

        let object = {
          agency: {
            name: agency.name,
            typeIdentification: typeIdentification,
            identification: agency.identification,
            phone: agency.phone,
          },
          producer: {
            id: response.data.registerAgency.user.idUser,
            name: producer.name,
            email: producer.email,
            phone: phone,
          }
        }

        this.setState({ messageSuccess: 'Registro Completado' })

        Interactor.postNotifyRegisterFast(object)
          .then(data => {
            resolve(response)
          })
          .catch(error => console.log(error))
      } catch (error) {
        console.log(error)
        console.log(error.graphQLErrors)
        this.handleErrors(error)
        reject(error)
      }
    })*/