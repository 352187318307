import React, { Fragment } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { withRouter } from 'react-router'
import Header from '../../../../components/header/Header';
import './InsertPassword.css'

const InsertPassword = ({
  email,
  title,
  updatePasswordUser,
  history
}) => {
  return (
    <Fragment>
      <div className="mw-100">
        <Header />
      </div>

      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}

        validationSchema={Yup.object({
          password: Yup.string().required("Por favor coloque una contraseña"),
          confirmPassword: Yup.mixed().test('pass', 'No coincide la contraseña', function (confirmPassword) {
            return confirmPassword === this.parent.password;
          }).required("Confirme contraseña"),
        })}

        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await updatePasswordUser(values)
            history.push('/dashboard')
          } catch (error) {
            console.log(error)
          }
        }} >

        {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue, dirty, }) => (
          <Form onSubmit={handleSubmit} id="form-insert-password" className="mt-4">
            <Container>
              <Card style={{ borderRadius: '15px', marginTop: '75px' }}>
                <CardBody>
                  <Row>
                    <Col sm={12} className="text-center">
                      <Label ><strong>{title}</strong></Label>
                    </Col>

                    {title.includes('Activar') && 
                      <Col sm={12} className="mt-2">
                        <Label>Define la contraseña que usarás para ingresar a Niriun.</Label>
                      </Col>
                    }
                  </Row>

                  <FormGroup className="mt-3">
                    <Label>Email *</Label>
                    <Input type="text" autoComplete="off" disabled
                      placeholder={email}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Nueva Contraseña *</Label>
                    <Input type="password" name="password" autoComplete="off"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                    <ErrorMessage name="password">
                      {msg => <div className="animated fadeIn" style={{ color: "red", marginTop: ".5rem" }}><small>{msg}</small></div>}
                    </ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <Label>Confirmar Contraseña *</Label>
                    <Input type="password" name="confirmPassword" autoComplete="off"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur} />
                    <ErrorMessage name="confirmPassword">
                      {msg => <div className="animated fadeIn" style={{ color: "red", marginTop: ".5rem" }}><small>{msg}</small></div>}
                    </ErrorMessage>
                  </FormGroup>

                  <Row className="text-center">
                    <Col>
                      <Button color="success" type="submit"
                        disabled={!dirty || isSubmitting}
                        style={{ cursor: !dirty || isSubmitting ? 'not-allowed' : 'pointer' }} >
                        {isSubmitting ? 'Cargando...' : 'Definir Contraseña'}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </Form>
        )}

      </Formik>
    </Fragment>
  )
}

export default withRouter(InsertPassword);