import React, { Fragment } from 'react';
import { Alert, Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/Header';
import './ForgotPassword.css'
import { CustomInput } from 'reactstrap';

const ForgotPasswordView = props => {
  const {
    loading,
    error,
    success,
    searchEmail,
    handleChangeError,
    initialValues,
    typeParam
  } = props;

  return (
    <Fragment>
      <div className="mw-100">
        <Header />
      </div>

      <div>
        <Formik
          initialValues={initialValues}

          validationSchema={Yup.object({
            email: Yup.string().email('Este email no es correcto').required('Por favor llene este campo'),
            agencia: Yup.boolean(),
            talento: Yup.boolean(),
          }).test(
            'both-false',
            'Selecciona al menos una opción',
            (values) => values.agencia || values.talento
          )}

          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              await searchEmail(values)
            } catch (error) {
              console.log(error)
            }
            // searchEmail(values)
            //   .then(data => )
            //   .catch(error => console.log(error))
          }} >

          {({ values, handleSubmit, handleBlur, setFieldValue }) => (
            <Form onSubmit={handleSubmit} id="form-forgot-password" className="mt-4">
              <Container>
                <Card style={{ borderRadius: '15px', marginTop: '75px' }}>
                  <CardBody>
                    {!success ?
                      <div>
                        <Row className="text-center">
                          <Col sm={12} >
                            <Label ><strong>Olvidaste tu contraseña</strong></Label>
                          </Col>

                          <Col sm={12} className="mt-3">
                            <p>Te enviaremos un correo para que puedas recuperar el acceso a tu cuenta.</p>
                          </Col>
                        </Row>

                        <FormGroup>
                          <Label>Correo Electronico *</Label>
                          <Input type="text" name="email" autoComplete="off"
                            value={values.email}
                            onChange={e => {
                              error && handleChangeError()
                              setFieldValue('email', e.target.value)
                            }}
                            onBlur={handleBlur} />
                          <ErrorMessage name="email">
                            {msg => <div className="animated fadeIn" style={{ color: "red", marginTop: ".5rem" }}><small>{msg}</small></div>}
                          </ErrorMessage>
                        </FormGroup>

                        {error &&
                          <Alert color="danger" className="mt-2 text-center">
                            {error}
                          </Alert>
                        }
                        {
                          !typeParam &&
                          <FormGroup className="d-flex justify-content-center align-items-center mt-3">
                            <Label check style={{ margin: '0rem 1rem' }}>
                              <CustomInput
                                type="checkbox"
                                id="agenciaCheckbox"
                                label="Agencia"
                                checked={values.agencia}
                                onChange={() => {
                                  setFieldValue('agencia', !values.agencia);
                                  setFieldValue('talento', false);
                                }}
                              />
                            </Label>

                            <Label check className="mr-4">
                              <CustomInput
                                type="checkbox"
                                id="talentoCheckbox"
                                label="Talento"
                                checked={values.talento}
                                onChange={() => {
                                  setFieldValue('talento', !values.talento);
                                  setFieldValue('agencia', false);
                                }}
                              />
                            </Label>

                            {(!values.agencia && !values.talento) && (
                              <div className="animated fadeIn" style={{ color: 'red', marginTop: '.5rem' }}>
                                <small>Selecciona al menos una opción</small>
                              </div>
                            )}
                          </FormGroup>
                        }

                        <Row className="text-center mt-4">
                          <Col>
                            <Button color="success">
                              {loading ? 'Cargando...' : 'Recuperar Contraseña'}
                            </Button>
                          </Col>
                        </Row>
                        {values.agencia &&
                          <Row className="text-center mt-3">
                            <Col>
                              <Link to="/login" className="return-login">Regresar al Login</Link>
                            </Col>
                          </Row>
                        }

                      </div>
                      :
                      <div>
                        <Form className="p-sm-5 text-center">
                          <Row>
                            <Col sm={12}>
                              <p>Te acabamos de enviar un correo electronico por favor revisalo.</p>
                            </Col>
                          </Row>
                          {values.agencia &&
                            <Row className="text-center mt-3">
                              <Col>
                                <Link to="/login" className="return-login">Regresar al Login</Link>
                              </Col>
                            </Row>
                          }
                        </Form>
                      </div>
                    }
                  </CardBody>
                </Card>
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

export default ForgotPasswordView;