import React, { Component } from 'react';
import { createPortal } from 'react-dom'
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
import ApplyAgent from './components/ApplyAgent/ApplyAgent.js'

import {
  // AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
// import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import logoPlataformWhite from '../../assets/img/brand/logo-blanco-niriun.png';

import './Styles/DefaultLayout.css'

class DefaultLayout extends Component {
  state = {
    isOpen: false,
    openModal: false,
  }

  isOpenModal = () => {
    this.setState({ isOpen: !this.state.isOpen }, () => {
      if (this.state.openModal) {
        setTimeout(() => {
          this.setState({ openModal: !this.state.openModal })
        }, 1000);
      } else {
        this.setState({ openModal: !this.state.openModal })
      }
    })
  }

  render() {
    const { isOpen, openModal } = this.state
    //console.log(...this.props)
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...this.props} />

            <Button color="success" title="Solicita un agente de servicio" size="md" className="text-white m-auto" style={{ paddingTop: 2, paddingBottom: 2 }}
              onClick={this.isOpenModal} >
              <i className="icon-user "></i><br />
            </Button>
            {/* <span className="m-auto">&nbsp;Solicitar Agente De Servicio</span> */}

            {openModal && createPortal(
              <ApplyAgent
                isOpen={isOpen}
                isOpenModal={this.isOpenModal} />
              , document.getElementById('modal'))}

            <img className="mt-2" src={logoPlataformWhite} alt="iTalentt" style={{ width: '48%', margin: 'auto', 'paddingBottom': '0.5rem' }} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => {
                  if (!localStorage.getItem('account')) {
                    return <Redirect to="/login" />
                  } else {
                    return (
                      <Container fluid className="default-layout-container">
                        <route.component {...props} />
                      </Container>
                    )
                  }
                }} />)
                  : (null);
              },
              )}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </main>
          {/* <AppAside fixed>
            <DefaultAside />
          </AppAside> */}
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
