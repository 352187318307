import React, { Component, Fragment } from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
// import { createPortal } from 'react-dom'
import ApplyAgent from './components/ApplyAgent/ApplyAgent.js'
import { ServiceFactory } from '../../controllers/services/ServiceFactory.js';
// import defaultUser from '../../assets/img/default/defaultUser.png';
import logo from '../../assets/img/default/defaultAgency.png';
import './Styles/DefaultHeader.css'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  state = {
    isOpen: false,
    openModal: false,
  }

  componentDidMount() {
    if (!localStorage.getItem('account')) {
      this.props.history.push('/login');
    }
  }

  handleLogout = () => {
    localStorage.clear();
    this.props.history.push('/login');
  }

  isOpenModal = () => {
    this.setState({ isOpen: true, openModal: true });
    // this.setState({ isOpen: !this.state.isOpen }, () => {
    //   if (this.state.openModal) {
    //     setTimeout(() => {
    //       this.setState({ openModal: !this.state.openModal })
    //     }, 1000);
    //   } else {
    //     this.setState({ openModal: !this.state.openModal })
    //   }
    // })
  }

  closeModal = () => {
    this.setState({ isOpen: false, openModal: false });
  }

  render() {
    const { history } = this.props;
    const { isOpen, openModal } = this.state;
    let account = {
      logoAgency: null,
      nameAgency: null,
      nameUser: null,
      lastnameUser: null,
      agency: null,
      legalNature: null,
      roleId: 0
    }

    let nameLastname = '';

    if (!localStorage.getItem('account')) {
      history.push('login')
    } else {
      account = JSON.parse(localStorage.getItem('account'))
      nameLastname = `${account.nameUser.split(' ')[0]} ${account.lastnameUser.split(' ')[0]}`
    }

    return (
      <Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: !account.logoAgency ? logo : `${ServiceFactory.serverImages}/${account.logoAgency}`,
            height: 50,
            alt: !account.logoAgency ? 'Defecto' : account.nameAgency,
            title: !account.logoAgency ? 'Defecto' : account.nameAgency,
          }}
        />

        <AppSidebarToggler className="d-md-down-none" display="lg" />

        {account.roleId !== 1 &&
          <Button
            color="success"
            title="Solicitar Agente"
            className="text-white mr-1 button-apply-agent"
            onClick={this.isOpenModal}
          >
            <i className="icon-user "></i>
            <span className="label-button-apply-agent">&nbsp;Solicitar Soporte Técnico</span>
          </Button>
        }

        {openModal && (
          <ApplyAgent isOpen={isOpen} isOpenModal={this.closeModal} />
        )}

        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav style={{ marginRight: '20px' }}>
              <div className="text-left d-flex align-items-center">
                <div className="mr-2">
                  <img src={!account.logoAgency ? logo : `${ServiceFactory.serverImages}/${account.logoAgency}`} className="rounded-circle photo-user" alt="" />
                </div>

                <div className="d-none d-sm-block">
                  <strong>{nameLastname}</strong>
                  <br />
                  {account.legalNature && account.legalNature.includes('comercio') && (
                    <small className="text-muted">{account.nameAgency}</small>
                  )}
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>Cuenta</strong>
              </DropdownItem>
              {account.roleId !== 1 &&
                <DropdownItem
                  onClick={() => history.push(`/perfil/${account.id}/${nameLastname.toLowerCase().replace(/[' ']/g, '-')}`)}
                >
                  <i className="fa fa-user"></i>Perfil
                </DropdownItem>
              }

              <DropdownItem onClick={this.handleLogout}>
                <i className="fa fa-lock"></i>Cerrar Sesión
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>

      </Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default withRouter(DefaultHeader);
