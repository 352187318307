import React, { Component, Fragment } from 'react';
import { compose, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom'
import InsertPassword from '../Components/InsertPassword/InsertPassword.jsx';
import { getEmailInActivateAccount, getStatesAgencyUser } from '../../../controllers/graphql/queries';
import { updatePassword, changePasswordTalent } from '../../../controllers/graphql/mutation';
import ServiceInteractor from '../../../controllers/services/ServiceInteractor';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)
const bcrypt = require('bcryptjs');
const Interactor = new ServiceInteractor()

class ActivateAccount extends Component {
  state = {
    idProducer: undefined,
    idAgency: undefined,
    idLocation: undefined,
    email: undefined,
    idTalent: undefined,
    states: {}
  }

  componentWillMount() {
    const { match } = this.props;
    console.log("match.params", match.params);
    match.params.type === 'agency' ? this.getEmailAndStates() : this.updatePasswordTalent(match.params);
  }

  updatePasswordTalent = async (params) => {
    this.setState({
      idTalent: params.id,
      email: params.email,
    })
  }

  getEmailAndStates = async () => {
    console.log("entra al getEmailAndStates");
    const { client, match, history } = this.props
    try {
      const response = await client.query({
        query: getEmailInActivateAccount,
        variables: { id: match.params.id }
      })

      const { data } = await client.query({ query: getStatesAgencyUser })

      this.setState({
        // idLocation: response.data.user.agencies.list[0].locations.list[0].id,
        idAgency: response.data.user.agencies.list[0].id,
        idProducer: response.data.user.id,
        email: response.data.user.email,
        states: data
      })
    } catch (error) {
      console.log(error);
      // history.push('/login')
    }
  }

  updatePasswordUser = (values) => {
    const { idProducer, idAgency, states, idTalent } = this.state;
    const { match, client } = this.props;
    return new Promise(async (resolve, reject) => {
      try {

        if (match.params.type === 'agency') {
          let mutation = updatePassword(match.path, {
            idUser: idProducer,
            idAgency,
            password: values.password,
            stateUser: states.statesUser.list[2].id,
            stateAgency: states.statesAgency.list[2].id,
          });

          const { data } = await client.mutate({ mutation })

          if (match.path.includes('/acti')) {
            const objectToSend = {
              data: {
                producer: `${data.updateUser.user.name} ${data.updateUser.user.lastname}`,
                email: data.updateUser.user.email,
                agency: data.updateAgency.agency.name,
                legalNature: data.updateAgency.agency.legalNature.replace(/[_' ']/g, '').toLowerCase(),
              }
            }

            await Interactor.postNotifyActivateAccount(objectToSend);
          }

          localStorage.setItem('account', JSON.stringify({
            id: data.updateUser.user.id,
            nameUser: data.updateUser.user.name,
            lastnameUser: data.updateUser.user.lastname,
            stateUser: data.updateUser.user.state,
            agency: data.updateUser.user.agencies.list[0].id,
            nameAgency: data.updateUser.user.agencies.list[0].name,
            logoAgency: data.updateUser.user.agencies.list[0].logo,
            phone: data.updateUser.user.phone,
            legalNature: data.updateUser.user.agencies.list[0].legalNature.includes('NATURAL') ? 'persona' : 'comercio',
          }))

          resolve(data)
        } else {
          const newPassword = await bcrypt.hash(values.password, 10);
          let mutation = changePasswordTalent(idTalent, newPassword);
          const { data } = await client.mutate({ mutation });
          
          if (data) {
            MySwal.fire({
              title: "Contraseña cambiada con exito",
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: `Aceptar`
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = 'https://niriun.com/';
              }
            })
          }
        }

      } catch (error) {
        console.log(error);
        reject(error);
        MySwal.fire({
          title: "Error al cambiar la contraseña",
          html: error,
          icon: 'error'
        })
      }
    })
  }

  render() {
    const { email } = this.state;
    const { match } = this.props;

    return (
      <Fragment>
        <InsertPassword
          email={email}
          title={match.path.includes('/acti') ? 'Activar Cuenta' : 'Restablecer Contraseña'}
          updatePasswordUser={this.updatePasswordUser} />
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withRouter
)(ActivateAccount);