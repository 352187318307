import { ServiceFactory } from './ServiceFactory';
import axios from 'axios';
const server = process.env.REACT_APP_API_ENDPOINT;
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}

const consumeServiceForSendEmail = (url, requirements) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, requirements)
      const data = await response.json();

      if (!response.ok || response.status === 202) {
        throw Error(JSON.stringify(data))
      }

      resolve(data)
    } catch (err) {
      const error = JSON.parse(err.message)
      reject(error)
    }
  })
}

class ServiceInteractor {
  postNotifyRegisterFast(data) {
    const url = `${server}${ServiceFactory.routes.notifyRegister}`;
    axios.post(url, data, headers).then(response => {
      console.log(response.data);
    }).catch(err => {
      console.log(err);
    });
  }

  postNotifyActivateAccount(data) {
    const url = `${server}${ServiceFactory.routes.notifyActiveAccount}`;
    axios.post(url, data, headers).then(response => {
      console.log(response.data);
    }).catch(err => {
      console.log(err);
    });
  }

  postNotifyForgotPassword(data) {
    const url = `${server}${ServiceFactory.routes.notifyForgotPassword}`;
    axios.post(url, data, headers).then(response => {
      console.log(response.data);
    }).catch(err => {
      console.log(err);
    });
  }

  postNotifyAdminUpdateDataProducer(data) {
    let url = `${ServiceFactory.serverAPI}${ServiceFactory.routes.notifyUpdateDataProducer}`;

    const requirements = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    return new Promise(async (resolve, reject) => {
      try {
        const response = await consumeServiceForSendEmail(url, requirements)
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  }

  postNotifyClient(data) {
    let url = `${ServiceFactory.serverAPI}${ServiceFactory.routes.notifyClient}`;
    // console.log(url);
    return new Promise(async (resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          if (!response.ok) {
            throw Error(JSON.stringify({
              status: response.status,
              message: response.statusText
            }))
          }

          return response.json();
        })
        .then(data => resolve(data))
        .catch(err => {
          //reject(err)
          //const { status, message } = JSON.parse(err.message)
          reject(JSON.parse(err.message))
        })
    })
  }

  postNotifyClientPersonalized(data) {
    let url = `${server}${ServiceFactory.routes.notifyClientPersonalized}`;

    axios.post(url, data, headers).then(response => {
      console.log(response.data);
    }).catch(err => {
      console.log(err);
    });
  }

  postNotifyClientGeneric(data) {
    let url = `${ServiceFactory.serverAPI}${ServiceFactory.routes.notifyClientGeneric}`;
    // console.log(url);
    return new Promise(async (resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(response => {
          if (!response.ok) {
            throw Error(JSON.stringify({
              status: response.status,
              message: response.statusText
            }))
          }

          return response.json();
        })
        .then(data => resolve(data))
        .catch(err => { reject(JSON.parse(err.message)) })
    })
  }

  postUploadImage(dataImage, nameUser, users) {
    let url = `${ServiceFactory.serverAPI}${ServiceFactory.routes.upload}`;
    return new Promise(async (resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: {
          // 'content-type': 'multipart/form-data',
          // "Access-Control-Allow-Origin": "*",
          'name-user': nameUser,
          'users': users
        },
        body: dataImage
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`status: ${response.status}, message: ${response.statusText}`)
          }
          return response.json()
        })
        .then(data => resolve(data))
        .catch(err => {
          console.log(err.message)
          reject(err)
          // if(err.message.includes('Server')){
          //   reject('Hubo un error intente más tarde')
          // }

          // if(err.message.includes('imagen valida')){
          //   reject(err.message)
          // }else{
          //   reject('Hubo un error')
          // }
        })
    })
  }

  postApplyAgentSchedule(data) {
    let url = `${server}${ServiceFactory.routes.notifyApplyAgentSchedule}`;
    // console.log("url", url);
    axios.post(url, data, headers).then(response => {
      console.log(response.data);
    }).catch(err => {
      console.log(err);
    });
  }

  postQuotation(data) {
    const url = `${server}${ServiceFactory.routes.notifyQuotation}`;
    // console.log("url: ", url);
    axios.post(url, data, headers).then(response => {
      console.log(response);
    }).catch(err => {
      console.log(err);
    });
  }

  sendPushNotification = async (data) => {
    try {
      const url = `${server}${ServiceFactory.routes.pushNotification}`
      const response = await axios.post(url, data, headers);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  saveContract = async (data) => {
    // console.log("data a mandar: ", data);
    const url = `${server}${ServiceFactory.routes.saveContract}`;
    
    return new Promise(async (resolve, reject) => {
      axios.post(url, data, headers).then(response => {
        console.log(response.data);
        resolve(response.data)
      }).catch(err => {
        console.log(err);
        reject(err); 
      });
    });
  };

  saveDocumentTalent = async (data, idTalent) => {
    const url = `${process.env.REACT_APP_URL_IMAGEN}/api/save-doc-contract/${idTalent}`;
    
    return new Promise(async (resolve, reject) => {
      axios.post(url, data, headers).then(response => {
        console.log(response.data);
        resolve(response.data)
      }).catch(err => {
        console.log(err);
        reject(err);
      });
    });
  };

  getEventsParams = async (token) => { //metodo que llama en varios componentes pero que nunca crearon

  }

}

export default ServiceInteractor;
