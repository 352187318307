import React, { Component } from 'react';
import Select from 'react-select';

const options = [
  { value: 'publicidad', label: 'Publicidad' },
  { value: 'moda', label: 'Moda' },
  { value: 'casting', label: 'Casting' },
  { value: 'fotografia', label: 'Fotografia' },
  { value: 'sesionBelleza', label: 'Sesiones de Belleza' }
];

class MySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleChange = value => {
    this.props.onChange('agency.services', value);
  }

  handleBlur = () => {
    this.props.onBlur('agency.services', true);
  }

  render() {
    return (
      <div>
        <Select
          options={options}
          isMulti={true}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
          noOptionsMessage={() => "No Hay Servicios"}
          placeholder="Servicios Ofrecidos" />
      </div>
    )
  }
}

export default MySelect;