import React from 'react';
import Loadable from 'react-loadable';
import { Container } from 'reactstrap';

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <Container><div>Cargando...</div></Container>;
}

const Colors = Loadable({
  loader: () => import('./views/Theme/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('./views/Theme/Typography'),
  loading: Loading,
});

/*const Announcement = Loadable({
  loader: () => import('./views/Announcement/Announcement'),
  loading: Loading,
});*/

const Profile = Loadable({
  loader: () => import('./views/Profile'),
  loading: Loading,
});

const Talent = Loadable({
  loader: () => import('./views/Talent'),
  loading: Loading,
});

const Talents = Loadable({
  loader: () => import('./views/Talents'),
  loading: Loading,
}); 

const Events = Loadable({
  loader: () => import('./views/Events'),
  loading: Loading,
});

const Clients = Loadable({
  loader: () => import('./views/Clients'),
  loading: Loading,
});

// const ListQuotation = Loadable({
//   loader: () => import('./views/Quote/List'),
//   loading: Loading,
// })

// const CreateQuotation = Loadable({
//   loader: () => import('./views/Quote/CreateQuotation'),
//   loading: Loading,
// });

const CreateQuotation = Loadable({
  loader: () => import('./views/QuoteFinal/CreateQuotation/CreateQuotation'),
  loading: Loading,
});

const ListQuotationPrueba = Loadable({
  loader: () => import('./views/QuoteFinal/List'),
  loading: Loading,
})

const EditQuotation = Loadable({
  loader: () => import('./views/QuoteFinal/List/pages/EditQuotation/EditQuotation'),
  loading: Loading,
})

const Event = Loadable({
  loader: () => import('./views/Event'),
  loading: Loading,
});

const Breadcrumbs = Loadable({
  loader: () => import('./views/Base/Breadcrumbs'),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import('./views/Base/Cards'),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import('./views/Base/Carousels'),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import('./views/Base/Collapses'),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import('./views/Base/Dropdowns'),
  loading: Loading,
});

const Forms = Loadable({
  loader: () => import('./views/Base/Forms'),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import('./views/Base/Jumbotrons'),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import('./views/Base/ListGroups'),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import('./views/Base/Navbars'),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import('./views/Base/Navs'),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import('./views/Base/Paginations'),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import('./views/Base/Popovers'),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import('./views/Base/ProgressBar'),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import('./views/Base/Switches'),
  loading: Loading,
});

const Tables = Loadable({
  loader: () => import('./views/Base/Tables'),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import('./views/Base/Tabs'),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import('./views/Base/Tooltips'),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import('./views/Buttons/BrandButtons'),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import('./views/Buttons/ButtonDropdowns'),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import('./views/Buttons/ButtonGroups'),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import('./views/Buttons/Buttons'),
  loading: Loading,
});

const Charts = Loadable({
  loader: () => import('./views/Charts'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const CoreUIIcons = Loadable({
  loader: () => import('./views/Icons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('./views/Icons/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('./views/Icons/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});

const Alerts = Loadable({
  loader: () => import('./views/Notifications/Alerts'),
  loading: Loading,
});

const Badges = Loadable({
  loader: () => import('./views/Notifications/Badges'),
  loading: Loading,
});

const Modals = Loadable({
  loader: () => import('./views/Notifications/Modals'),
  loading: Loading,
});

const Widgets = Loadable({
  loader: () => import('./views/Widgets/Widgets'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./views/Users/User'),
  loading: Loading,
});

const Analytic = Loadable({
  loader: () => import('./views/Analytic/Analytic'),
  loading: Loading,
});

const Agency = Loadable({
  loader: () => import('./views/Agency/Agency'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  //{ path: '/eventos', name: 'Eventos', component: EventsClients },
  { path: '/', exact: true, name: 'Dashboard', component: DefaultLayout },
  { path: '/dashboard', component: Dashboard },
  { path: '/clientes', name: 'Clientes', component: Clients },
  { path: '/eventos', name: 'Eventos', component: Events },
  { path: '/cotizador', exact: true, name: 'Cotizador', component: CreateQuotation },
  { path: '/cotizador/crear', name: 'Crear', component: CreateQuotation },
  { path: '/cotizador/listado', name: 'Lista Cotizaciones', exact: true, component: ListQuotationPrueba },
  { path: '/cotizador/listado/cotizacion/:id', exact: true, name: 'Editar Cotización', component: EditQuotation },
  { path: '/cotizador-prueba', exact: true, name: 'Cotizador Prueba', component: CreateQuotation },
  { path: '/perfil/:id/:name', name: 'Perfil', component: Profile },
  { path: '/evento/:id/:name/talento/:idTalent', exact: true, name: 'Talento', component: Talent },
  { path: '/evento/:id/:name', exact: true, name: 'Evento', component: Event },
  { path: '/talentos', exact: true, name: 'Talentos', component: Talents },
  /*{ path: '/convocatoria', name: 'Crear Convocatoria', component: Announcement },*/
  { path: '/theme', exact: true, name: 'Theme', component: Colors },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', exact: true, name: 'Base', component: Cards },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/forms', name: 'Forms', component: Forms },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },
  { path: '/Analytic', exact: true, name: 'Analytic', component: Analytic },
  { path: '/agencias', exact: true, name: 'Agencias', component: Agency },
];

export default routes;
