import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import logoBlack from '../../../assets/img/brand/italentt1.png'
import model from '../../../assets/img/talentos/modelo-confirm-email.png'
import './ConfirmEmailView.css'

const ConfirmEmailView = () => {

  return (
    <Fragment>
      <div className="page-main">
        <Container className="page-main-container">
          <Row>
            <Col xs={12} md={7} className="mt-5 col-revisa-email">
              <Row>
                <Col>
                  <h4 className="parrafo-extra text-justify">Has creado tu cuenta con:</h4>
                </Col>
              </Row>

              <Row className="mt-3 text-center">
                <Col sm={12}>
                  <img style={{width: '25rem'}} src={"https://niriun.com/wp-content/uploads/2023/11/niriun-home.png"} alt="Niriun" title="Niriun" />
                </Col>
              </Row>

              <Row className="mt-5">
                <Col>
                  <h4 className="parrafo-revisa-email">
                    ¡Felicidades, has ingresado exitosamente tus datos!
                    Revisa tu e-mail, en tu bandeja de entrada dejamos un correo para que actives tu cuenta.
                  </h4>
                </Col>
  
              </Row>

              <Row className="mt-5 row-parrafo-extra">
                <Col sm={12}>
                  <h5>
                    Si no te aparece, no olvides revisar tu bandeja de SPAM. <br/>
                    ¿Sigues sin encontrarlo? escríbenos a&nbsp;
                    <a href="mailto:contacto@niriun.com?Subject=Soporte%20Registro&body=Hola%20no%20me%20llegó%20el%20correo%20de%20activación">
                      contacto@niriun.com
                    </a> 
                    &nbsp;y te resolveremos el problema.
                  </h5>

                </Col>

                {/* <Col sm={12}>
                  <Link to="/login">Regresar al login</Link>
                </Col> */}
              </Row>
            </Col>

            <Col xs={12} md={5} className="text-center col-photo-model">
              <img className="photo-model" src={model} alt="modelo" title="modelo" />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default ConfirmEmailView;
