import React, { Component, Fragment } from 'react';
import { Col, Row, Button } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../styles/GalleryPhotos.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createPortal } from 'react-dom'
import ModalDeletePhoto from '../Modals/ModalDeletePhoto/ModalDeletePhoto';
import ModalChangeTypePhoto from '../Modals/ModalChangeTypePhoto/ModalChangeTypePhoto';

const MySwal = withReactContent(Swal);

class IBook extends Component {
  state = {
    isOpen: false,
    images: [],
    photoIndex: 0,
    openModal: false,
    openModalChange: false,
    indexSelect: null,
    imageSelecte: null
  }

  componentDidMount() {
    const { images } = this.props;
    this.setState({ images })
  }

  componentDidUpdate(prevProps) {
    const { images } = this.props;
    const prevImages = prevProps.images;

    // Verificar si las imágenes han cambiado
    if (JSON.stringify(images) !== JSON.stringify(prevImages)) {
      this.setState({ images });
    }
  }

  lightBox = (index) => () => this.setState({ isOpen: true, photoIndex: index })
  close = () => this.setState({ isOpen: false })

  prev = () => {
    const { photoIndex, images } = this.state;
    this.setState({ photoIndex: (photoIndex + images.length - 1) % images.length })
  }

  next = () => {
    const { photoIndex, images } = this.state;
    this.setState({ photoIndex: (photoIndex + 1) % images.length, })
  }

  handleOpenModalDelete = (index) => {
    this.setState({ openModal: true, indexSelect: index })
  }

  handleOpenModalChange = (index, image) => {
    this.setState({ openModalChange: true, indexSelect: index, imageSelecte: image })
  }

  handleCloseModal = () => {
    this.setState({ openModal: false, openModalChange: false });
  }

  handleAprobar = (index) => {
    const { images } = this.props;
    MySwal.fire({
      title: "¿Desea aprobar la imagen?",
      // html: `<p>Si editas la cotización, te saldrás del modulo de la creación del evento.</p>`,
      icon: 'question',
      iconColor: '#fece0c',
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      confirmButtonColor: '#4dbd74',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '##cdcdcd',
    }).then((result) => {
      if (result.isConfirmed) {
        // Actualiza el estadoId de la imagen en el array de valores iniciales
        this.props.setFieldValue('talent.images.list', [
          ...images.slice(0, index),
          { ...images[index], stateId: 82, observation: 'Ninguna' }, // Actualiza el estadoId a 82
          ...images.slice(index + 1),
        ]);
      }
    })
  };

  handleRechazar = (typeReyect, index) => {
    const { images } = this.props;
    try {
      // Actualiza el estadoId de la imagen en el array de valores iniciales
      this.props.setFieldValue('talent.images.list', [
        ...images.slice(0, index),
        { ...images[index], stateId: 83, observation: typeReyect.toString() }, // Actualiza el estadoId a 83
        ...images.slice(index + 1),
      ]);

      this.setState({ openModal: false })
    } catch (error) {
      console.log("error al seleccionar");
    }
  };

  handleChange = (selectedOption, index) => {
    const { images } = this.props;
    try {
      this.props.setFieldValue('talent.images.list', [
        ...images.slice(0, index),
        { ...images[index], type: selectedOption.toString()}, 
        ...images.slice(index + 1),
      ]);      
      
      this.setState({ openModalChange: false })
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { isOpen, photoIndex, images, openModal, openModalChange, 
      indexSelect, imageSelecte } = this.state;

    return (
      <Fragment>
        <Row>
          {images.map((image, index) => {
            if (image.type.toLowerCase() == 'ibook') {
              const borderStyle = {
                border: image.stateId === 81 ? '3px solid #eec000' :
                  image.stateId === 82 ? '3px solid #27DA33' :
                    image.stateId === 83 ? '3px solid red' :
                      image.stateId === 84 ? '3px solid gray' : 'none',
                borderRadius: '0px 10px'
              };
              return (
                <Col key={index} xs={6} md={3} style={{ cursor: 'pointer' }}>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img
                      style={{ width: '100%', marginTop: "5px", ...(this.props.adminTalent && borderStyle) }}
                      src={
                        image.route.startsWith('http') ? image.route :
                          `${process.env.REACT_APP_URL_IMAGEN}${process.env.REACT_APP_URL_STORAGE_IMAGEN}${image.route}`
                      }
                      alt=""
                      onClick={this.lightBox(index)} />
                    {
                      this.props.adminTalent &&
                      <div style={{ position: 'absolute', top: 3, left: 0 }}>
                        <i style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => this.handleOpenModalChange(index, image)}
                        className="fa fa-pencil-square pencil-icon" aria-hidden="true"></i>
                      </div>
                    }
                  </div>

                  {
                    (this.props.adminTalent) &&
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <Button
                        style={{ width: '100%', borderRadius: '10px 10px' }}
                        disabled={image.stateId === 82 || image.stateId == 84}
                        size='sm'
                        title='Aprobado'
                        color='success'
                        onClick={() => this.handleAprobar(index)}
                      >
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </Button>

                      <Button
                        style={{ width: '100%', borderRadius: '10px 10px' }}
                        disabled={image.stateId === 83 || image.stateId == 84}
                        size='sm'
                        title='Rechazado'
                        color='warning'
                        onClick={() => this.handleOpenModalDelete(index)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </div>
                  }
                </Col>
              )
            }
          })}

          {
            openModal && createPortal(
              <ModalDeletePhoto
                indexSelect={indexSelect}
                handleCloseModal={this.handleCloseModal}
                isOpen={openModal}
                handleRechazar={this.handleRechazar}
              />, document.getElementById('modal')
            )
          }

          {
            openModalChange && createPortal(
              <ModalChangeTypePhoto
                indexSelect={indexSelect}
                imageSelecte={imageSelecte}
                handleCloseModal={this.handleCloseModal}
                isOpen={openModalChange}
                handleChange={this.handleChange}
              />, document.getElementById('modal')
            )
          }

          {isOpen && (
            <Lightbox
              mainSrc={
                images[photoIndex].route.startsWith('http') ? images[photoIndex].route
                  : `${process.env.REACT_APP_URL_IMAGEN}${process.env.REACT_APP_URL_STORAGE_IMAGEN}${images[photoIndex].route}`
              }
              nextSrc={images[(photoIndex + 1) % images.length].route}
              prevSrc={images[(photoIndex + images.length - 1) % images.length].route}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={this.prev}
              onMoveNextRequest={this.next}
              animationDuration={400}
              enableZoom={false}
              animationOnKeyInput={true}
              imageCaption={`${photoIndex + 1}/${images.length}`} />
          )}
        </Row>
      </Fragment>
    );
  }
}

export default IBook;
