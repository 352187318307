import React, { Component, Fragment } from 'react';
import { compose, graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import TalentView from './TalentView';
import { GET_TALENT, GET_EVENT_BY_ID } from '../../controllers/graphql/queries';

class Talent extends Component {
  returnEvent = () => this.props.history.goBack();

  state = {
    validEventClient: false,
    eventId: null
  }

  componentWillMount= async()=> {
    const { match, client } = this.props;

    if (match.params.id_event) {

      const { data } = await client.query({
        query: GET_EVENT_BY_ID,
        variables: { id: match.params.id_event },
        fetchPolicy: 'network-only'
      })

      const invalidStates = [36, 37, 38];

      if (!invalidStates.includes(data.event.stateId)) {
        this.setState({
          validEventClient: true,
        });
      }

      this.setState({
        eventId: match.params.id_event
      });
    }
  }

  render() {    
    const { returnEvent } = this;
    const { validEventClient, eventId } = this.state;
    const { getTalent, adminTalent } = this.props;
    if (getTalent.loading) return 'Cargando...'
    //TODO: Colocar un error en caso de que traiga la query mal o produzca un error

    return (
      <Fragment>
        <TalentView
          adminTalent={adminTalent}
          returnEvent={returnEvent}
          talent={getTalent.talent}
          eventId={eventId} 
          validEventClient={validEventClient}/>
      </Fragment>
    )
  }
}

export default compose(
  withApollo,
  withRouter,
  graphql(GET_TALENT, {
    name: 'getTalent',
    options: ({ match, adminTalent }) => ({
      variables: {
        id: adminTalent || (match && match.params.idTalent) || null,
      },
    }),
  })
)(Talent);
