import React, { Fragment } from 'react'
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Card, CardBody, Col, Container, Form, Label, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import DefaultFooter from '../../../containers/DefaultLayout/DefaultFooter';
import Header from '../../../components/header/Header';
import { AppFooter } from '@coreui/react';
import './login.css'

const LoginView = ({
  statePass,
  changeStateMessage,
  messageState,
  error,
  showPass,
  login,
  // changeStateError,
  // history,
  // states
}) => {
  return (
    <Fragment>
      <div className="app">
        <div>
          <Header />
        </div>

        <Row className='body-container'>
          <Col md={12} className='no-padding'>
            <Formik
              initialValues={{
                user: '',
                password: '',
              }}

              validationSchema={Yup.object().shape({
                user: Yup.string().email('Este email no es correcto').required('Por favor llene este campo'),
                password: Yup.string().required('Por favor coloque una contraseña')
              })}

              onSubmit={async (values, { setSubmitting }) => {
                try {
                  await login(values);

                  setSubmitting(false)
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue, dirty }) => (
                <Form onSubmit={handleSubmit} className="login">
                  <Row style={{ width: '100%' }}>
                    <Col md={7} lg={8} className='no-padding imgcontainer'>
                      <div className="login-image">
                        <div className="image-container"></div>
                        <div className="text-overlay">
                          Si eres una Agencia de Talentos (Ofreces a tus clientes los servicios de Actores, Modelos, Presentadores) podrás gestionar en línea: cotizaciones, clientes y convocatorias, de manera eficiente.
                          <br /><br />
                          Acceso y monitoreo de información en tiempo real desde cualquier dispositivo móvil o de escritorio.
                        </div>
                      </div>
                    </Col>

                    <Col md={5} lg={4}>
                      <Row className='top'>
                        <Col md={12}>
                          <div className="mt-5 text-center">
                            <h3><strong>Inicia Sesión en tu rol de Agencia de Talentos</strong></h3>
                          </div>
                        </Col>
                        <Col sm={12} className='mt-3'>
                          <Label>Correo Electronico: </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-envelope-o"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text" maxLength="100" name="user" placeholder="Correo Electronico" autoComplete="off"
                              onBlur={handleBlur}
                              value={values.user}
                              onChange={e => {
                                setFieldValue('user', e.target.value)
                                changeStateMessage()
                              }} />
                          </InputGroup>
                          <ErrorMessage name="user">
                            {msg => <div className="field-error"><small className="form-text text-danger"><strong>{msg}</strong></small></div>}
                          </ErrorMessage>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col sm={12}>
                          <Label>Contraseña: </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type={statePass ? 'text' : 'password'} name="password" placeholder="Contraseña"
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={e => {
                                setFieldValue('password', e.target.value)
                                changeStateMessage()
                              }} />
                            <div className="eye" style={{ cursor: 'pointer' }} >
                              <i className={statePass ? 'far fa-eye-slash' : 'far fa-eye'} onClick={() => showPass()}></i>
                            </div>
                          </InputGroup>
                          <ErrorMessage name="password">
                            {msg => <div className="field-error"><small className="form-text text-danger"><strong>{msg}</strong></small></div>}
                          </ErrorMessage>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col sm={12}>
                          <Button
                            type="submit"
                            className="text-center btn-login"
                            color="primary"
                            disabled={!dirty || isSubmitting}
                            style={{ cursor: !dirty || isSubmitting ? 'not-allowed' : 'pointer' }} >
                            {isSubmitting ? 'Cargando...' : 'Iniciar sesión'}
                          </Button>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col className='center'>
                          <Link to="/olvidaste-contrasena?select=agency" style={{ textDecoration: 'none' }} > ¿Olvidaste tu contraseña? </Link>
                        </Col>
                      </Row>

                      <Row>
                        <Col className='center'>
                          <Label>
                            ¿Eres una agencia?&nbsp;
                            <Link to="/registrofast" className="register"> <strong >Regístrate ahora</strong> </Link>
                          </Label>
                        </Col>
                      </Row>

                      {messageState !== '' ?
                        <Alert className="mt-3" style={{ textAlign: 'center' }} color="primary">
                          {messageState}
                        </Alert>
                        : ''}
                      {error &&
                        <Alert className="mt-3" style={{ textAlign: 'center' }} color="danger">
                          {error}
                        </Alert>}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>

      </div>
    </Fragment>
  )
}

export default withRouter(LoginView);
