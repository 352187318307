import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from '@apollo/client';
// import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
// import { getMainDefinition } from '@apollo/client/utilities';
// import { split } from '@apollo/client/link/core';
// import { WebSocketLink } from '@apollo/client/link/ws';
import { ServiceFactory } from './controllers/services/ServiceFactory';

// Configuración del enlace HTTP
const httpLink = new HttpLink({
  uri: `${ServiceFactory.serverGraphQL}`,
})

const wsUri =
  ServiceFactory.serverGraphQL.startsWith('http')
    ? `ws${ServiceFactory.serverGraphQL.slice(4)}`
    : ServiceFactory.serverGraphQL.startsWith('https')
      ? `ws${ServiceFactory.serverGraphQL.slice(5)}`
      : `ws${ServiceFactory.serverGraphQL}`;

console.log("wsUri", wsUri);

// Configuración del enlace WebSocket
// const wsLink = new WebSocketLink({
//   uri: wsUri, // Ajusta según tu configuración
//   // uri: `ws://${ServiceFactory.serverGraphQL.replace(/^http/, 'ws')}`,
//   options: {
//     reconnect: true,
//   },
// });

// //Usar el enlace WebSocket para las suscripciones y el enlace HTTP para las demás operaciones
// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   wsLink,
//   httpLink
// );

const client = new ApolloClient({
  link: httpLink,
  // link: splitLink,
  cache: new InMemoryCache()
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
