import React, { Fragment } from 'react';
import { Col, Label, Row, Button } from 'reactstrap';
import DefaultFooter from '../../../containers/DefaultLayout/DefaultFooter';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { AppFooter } from '@coreui/react';
import './ShowTalentEvent.css'
import defaultWoman from '../../../assets/img/default/defaultWoman.png';
import defaultMan from '../../../assets/img/default/defaultWoman.png';
import defaultAgency from '../../../assets/img/default/defaultAgency.png';

const LoadingSpinner = () => {
    const override = css`
        display: block;
        margin: 0 auto;
        color: #20a8d8;
        size: 100;
    `;

    return (
        <ClipLoader loading={true} css={override} />
    );
};

const ShowTalentEventView = props => {

    const {
        loading,
        eventValid,
        event,
        url,
        talentList,
        openAditionalProfile,
        openProfile
    } = props

    const startDate = new Date(event.startDate);
    const endDate = new Date(event.endDate);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };

    const generateTalent = (talent, index) => {
        let photoDefault;
        if (talent.gender === 'MASCULINO') {
            photoDefault = defaultMan
        } else {
            photoDefault = defaultWoman
        }

        return (
            <Col md={2} key={index} style={{ maxWidth: 'none' }}>
                <div className='talent'>
                    <img
                        onClick={() => openProfile(talent.id)}
                        className="image-selected"
                        title={`${talent.name}`}
                        name="viewProfile"
                        alt={`${talent.name}`}
                        src={
                            talent.photo
                                ? talent.photo.startsWith('http')
                                    ? talent.photo
                                    : `${process.env.REACT_APP_URL_IMAGEN}${process.env.REACT_APP_URL_STORAGE_IMAGEN}${talent.photo}`
                                : photoDefault
                        } />
                    <br />
                    <Label className="mt-2 mb-0 text-center"><strong>{talent.name} {talent.lastname}</strong></Label>
                    <br />
                </div>
            </Col>
        )
    }

    console.log("event.finalClientColumn: ", event);
    return (
        <Fragment>
            <div className="app">
                <Row className="margin-app app-body-show">
                    {loading ? <LoadingSpinner /> :
                        <Row className='margin-app' style={{ display: "flex", justifyContent: "center" }}>
                            {eventValid ?
                                <Row style={{ width: '100%', justifyContent: "center" }}>
                                    <Col lg={12} style={{ marginBottom: "2rem" }}>
                                        <Row className="info-porfolio">
                                            {
                                                (event.finalClientColumn == true || event.finalClientColumn == null) ?
                                                    <Col sm={12} md={4} className="logo-container item-card subitem-card">
                                                        <img src={
                                                            event?.quotation?.agency?.logo
                                                                ? `${process.env.REACT_APP_API_ENDPOINT}/static/images/${event.quotation.agency.logo}`
                                                                : defaultAgency
                                                        } alt="Logo Circular" className="logo" />
                                                        <p> <strong>Agencia:</strong>  {event.quotation.agency.name}</p>
                                                    </Col>
                                                    :
                                                    <Col sm={12} md={4} className="item-card subitem-card">
                                                        <Row>
                                                            <Col md={6} className="logo-container">
                                                                <img src={
                                                                    event?.quotation?.agency?.logo
                                                                        ? `${process.env.REACT_APP_API_ENDPOINT}/static/images/${event?.quotation?.client?.logo}`
                                                                        : defaultAgency
                                                                } alt="Logo Circular" className="logo-finalClient" />
                                                                <p> <strong>Agencia:</strong>  {event?.quotation?.client?.name}</p>
                                                            </Col>
                                                            <Col md={6} className="logo-container">
                                                                <img src={
                                                                    event?.quotation?.agency?.logo
                                                                        ? `${process.env.REACT_APP_API_ENDPOINT}/static/images/${event?.finalClientRelation?.urlLogo}`
                                                                        : defaultAgency
                                                                } alt="Logo Circular" className="logo-finalClient" />
                                                                <p> <strong>Cliente:</strong>  {event?.finalClientRelation?.name}</p>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                            }

                                            <Col sm={12} md={4} className="event-details item-card subitem-left">
                                                <p> <strong>Nombre del evento:</strong> {event.name}</p>
                                                <p> <strong>Código evento:</strong>  {event.quotation.code}</p>
                                                <p>
                                                    <strong>Fecha inicial:</strong> {startDate.toLocaleDateString('es-ES', options)}
                                                </p>
                                                <p>
                                                    <strong>Fecha Final:</strong> {endDate.toLocaleDateString('es-ES', options)}
                                                </p>

                                            </Col>
                                            <Col md={4} className='item-card item-last'>
                                                <p> <strong>Luagar del evento:</strong> {event.eventDirectory.places.name}</p>
                                                <p> <strong>Ciudad:</strong> {event.eventDirectory.places.city.name}</p>
                                                <p> <strong>Dirección:</strong> {event.eventDirectory.places.address}</p>
                                                <Button
                                                    className="btn btn-success"
                                                    style={{ cursor: event.additionalProfiles ? 'pointer' : 'not-allowed' }}
                                                    disabled={!event.additionalProfiles}
                                                    onClick={openAditionalProfile}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p style={{ display: 'contents' }}><b>Perfiles Adicionales</b></p>
                                                    </div>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {talentList.length !== 0 && talentList.map((talent, index) => {
                                        let convocated = [];

                                        if (talent.stateId != 1812 && (talent.aplicationTalentState != 1817 && talent.aplicationTalentState != 1816)) {
                                            convocated.push(generateTalent(talent.talent, index));

                                            return (
                                                <Fragment key={index}>
                                                    <Col md={3} className="div-borde">
                                                        <Row className="animated fadeIn text-center d-flex justify-content-center">
                                                            {convocated}
                                                        </Row>
                                                    </Col>
                                                </Fragment>
                                            )
                                        }
                                    })}
                                </Row>
                                :
                                <Col>
                                    <h1>Su evento está caducado.</h1>
                                </Col>

                            }
                        </Row>
                    }
                </Row>
                <AppFooter>
                    <DefaultFooter />
                </AppFooter>
            </div>
        </Fragment>
    )
}

export default ShowTalentEventView;
