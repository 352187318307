import React, { Fragment } from 'react';
import { Alert, Button, Form, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import './ApplyAgentView.css'

const ApplyAgentView = props => {
  const {
    isOpen,
    isOpenModal,
    events,
    handleActions,
    schedule,
    showInputPhone,
    isOpenAlert,
    loading,
    dismissing
  } = props;

  const { phone } = JSON.parse(localStorage.getItem('account'))

  return (
    <Fragment>
      <Formik
        initialValues={{
          phone: phone,
          event: '',
        }}

        validationSchema={
          Yup.object().shape({
            phone: Yup.string().required('Por favor llene este campo')
          })
        }

        onSubmit={async (values, { setSubmitting, resetForm }, type) => {
          try {
            await schedule(values)
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {({ values, isSubmitting, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue, handleSelect, setFieldTouched, submitForm }) => (
          <Modal isOpen={isOpen}>
            <ModalHeader toggle={!loading && isOpenModal} >
              Solicita Soporte Técnico.
            </ModalHeader>

            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <Label>Selecciona el Evento para el que necesitas Soporte Técnico</Label>
                <div>
                  <Select
                    name="event"
                    options={events}
                    value={values.event}
                    isClearable
                    isSearchable
                    placeholder="Nombre del evento"
                    noOptionsMessage={() => 'No hay evento'}
                    onBlur={handleBlur}
                    isDisabled={loading}
                    onChange={e => {
                      // console.log("seleccionado", e);
                      setFieldValue('event', e ? e : '')
                    }}
                  />
                  <ErrorMessage name="event">
                    {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                  </ErrorMessage>
                </div>
                {values.event &&
                  <div className="my-2">
                    <Label className="mt-3">¿Que deseas hacer?</Label>
                    <div>
                    <Button
                      className="mr-1 btn-pill"
                      type="button"
                      color="primary"
                      // color={values.event === '' ? "secondary" : 'primary'}
                      // disabled={values.event === ''}
                      // style={{ cursor: values.event === '' ? 'not-allowed' : 'pointer' }}
                      onClick={() => handleActions(1, values.event)}
                    >
                      <i className="icon-call-in"></i> Agendar
                    </Button>

                    <a href="tel:3004701318">
                      <Button
                        className="mr-1 d-md-none btn-pill"
                        type="button"
                        color="light"
                        // color={values.event === '' ? "secondary" : 'light'}
                        // disabled={values.event === ''}
                        // style={{ cursor: values.event === '' ? 'not-allowed' : 'pointer' }}
                        onClick={() => handleActions(2, values.event)} >
                        <i className="fa fa-phone"></i> Llámanos
                      </Button>
                    </a>

                    <Button
                      type="button"
                      className="btn-pill"
                      color="success"
                      // color={values.event === '' ? "secondary" : 'success'}
                      // disabled={values.event === ''}
                      // style={{ cursor: values.event === '' ? 'not-allowed' : 'pointer' }}
                      onClick={() => handleActions(3, values.event)} >
                      <i className="fa fa-whatsapp"></i> Escribir
                    </Button>
                    </div>
                  </div>}
                {showInputPhone &&
                  <Fragment>
                    <div className="mt-3">
                      <Label>Ingresa tu número de teléfono</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-screen-smartphone"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field type="text" name="phone" component="input"
                          render={({ field }) => {
                            return <MaskedInput
                              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                              {...field}
                              placeholder="Celular"
                              disabled={loading}
                              guide={false}
                              autoComplete="off"
                              className="form-control"
                              value={values.phone}
                              onBlur={handleBlur}
                            // onChange={e => {
                            //   error && handleChangeError()
                            //   setFieldValue("client.phone", e.target.value)
                            // }} 
                            />
                          }} />
                      </InputGroup>
                      <ErrorMessage name="phone">
                        {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                      </ErrorMessage>
                    </div>

                    {!loading && isOpenAlert &&
                      <div className="mt-3">
                        <Alert isOpen={isOpenAlert} toggle={dismissing} color="success mb-0">
                          <h4 className="alert-heading">Excelente!</h4>
                          <p>
                            Se ha enviado un correo al Soporte Técnico de Niriun. <br />
                            En unos minutos se estará comunicando contigo.
                          </p>
                        </Alert>
                      </div>
                    }
                  </Fragment>
                }
              </ModalBody>
              {showInputPhone &&
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={isOpenModal}
                    disabled={loading}
                    style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    type="submit"
                    color={'success'}
                    disabled={loading || values.phone === ''}
                    style={{ cursor: loading || values.phone === '' ? 'not-allowed' : 'pointer' }}
                  >
                    {loading ? 'Cargando...' : 'Enviar'}
                  </Button>
                </ModalFooter>
              }
            </Form>
          </Modal>
        )}
      </Formik>
    </Fragment>
  );
};

export default ApplyAgentView;
