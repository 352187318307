import React, { Component, Fragment } from 'react';
import { Label } from 'reactstrap';

class Skills extends Component {
  state = {}

  render() {
    const { skills } = this.props;

    return (
      <Fragment>
        {skills && skills.length !== 0 ? (
          skills.map(skill => {
            return (
              <div key={skill.node.id}>
                {
                   (skill.node.skill.areaSkills.nodes[0].areaId !== 1 && skill.node.skill.areaSkills.nodes[0].areaId !== 8) &&
                  <Label>
                    <i className="fas fa-angle-right"></i>
                    &nbsp;{skill.node.skill.name}
                  </Label>
                }
              </div>
            );
          })
        ) : (
          <div className="text-center">No tiene ninguna aptitud</div>
        )}
      </Fragment>
    );
  }
}

export default Skills;
