//Libreries
import React, { Component } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Card, CardBody, Container, Col, FormText, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import MaskedInput from 'react-text-mask';

//Assets
import Header from '../../../components/header/Header';
// import HeaderNotification from '../../../components/header/HeaderNotification';
import MySelect from './Components/MySelect';

//Styles
import '../../../scss/_variables.scss';
import './Register.css';


export default class Register extends Component {

  constructor(props) {
    super(props)
    const paramsAgencies = JSON.parse(localStorage.getItem('params-agencies'));

    this.state = {
      generateFieldPassword: 1,
      messagePassword: 0,
      object: {},
      modal: false,
      countries: paramsAgencies ? paramsAgencies.countries : [],
      enabledInput: false,
      listCities: []
    };
  }

  handleCheckGenerateDivPassword = (e) => {
    const nameRadio = e.target.value;

    if (nameRadio === 'radioManual')
      this.setState({ generateFieldPassword: 1, });
    else
      this.setState({ generateFieldPassword: 2, });
  }

  render() {
    return (
      <div>
        <div className="mw-100">
          <Header />
        </div>
        <div>
          <Formik
            initialValues={{
              agency:
              {
                nameAgency: '',
                typeDocument: '',
                identification: '',
                countries: '',
                cities: '',
                phone: '',
                url: '',
                mission: '',
                services: [],
                address: ''
              },
              productor: {
                nameProductor: '',
                emailProductor: '',
                phoneProductor: '',
                password: '',
                verifyPassword: ''
              }
            }}

            validationSchema={Yup.object({
              agency: Yup.object({
                //nameAgency: Yup.string().matches(/^[a-zA-Z]+$/, 'Nombre Completo').required('Por favor llene este campo'),
                nameAgency: Yup.string().required('Por favor llene este campo'),
                typeDocument: Yup.string().required('Seleccione una opción'),
                identification: Yup.string().required('Por favor llene este campo'),
                countries: Yup.string().required('Seleccione el País'),
                cities: Yup.string().required('Seleccione la ciudad'),
                //url: Yup.string().url('Esta url no es valida').required('Por favor llene este campo'),
                url: Yup.string().required('Por favor llene este campo'),
                phone: Yup.string().required('Por favor llene este campo'),
                address: Yup.string().required('Por favor llene este campo')
              }),
              productor: Yup.object({
                nameProductor: Yup.string().required('Por favor llene este campo'),
                emailProductor: Yup.string().email('Este email no es correcto').required('Por favor llene este campo'),
                phoneProductor: Yup.string().required('Por favor llene este campo'),
                password: Yup.string().required("Por favor coloque una contraseña"),
                verifyPassword: Yup.mixed().test('pass', 'No coincide la contraseña', function (verifyPassword) {
                  return verifyPassword === this.parent.password;
                }).required("Contraseña Correcta Requerida"),
              })
            })}

            onSubmit={(values, { setSubmitting, resetForm }) => {
              this.setState({ modal: !this.state.modal });
            }}>

            {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
              <form onSubmit={handleSubmit} >
                <section>
                  <Container className="mt-3">
                    {/*<div className="text-center">
                      <Label style={{fontSize: '25px'}}>Registro Agencias</Label>
                    </div>*/}
                    <Card className="mt-4" style={{ borderRadius: '15px' }} >
                      <CardBody>
                        <div>
                          <Label><strong>Registre su Agencia</strong></Label>
                          <hr className="my-0" />
                        </div>

                        <div id="primeryCamp" className="mt-4">
                          <Row form>
                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-people"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" name="agency.nameAgency" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+" maxLength="50" autoComplete="off" placeholder="Nombre de Agencia"
                                  value={values.agency.nameAgency}
                                  onChange={e => {
                                    const value = (e.target.validity.valid) ? e.target.value : values.agency.nameAgency
                                    setFieldValue('agency.nameAgency', value)
                                  }}
                                  onBlur={handleBlur} />
                              </InputGroup>
                              <ErrorMessage name="agency.nameAgency">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>

                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-wallet"> </i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Field component="select" className="form-control" name="agency.typeDocument"
                                  onChange={handleChange}
                                  value={values.agency.typeDocument}>
                                  <option value="">Tipo de Documento</option>
                                  <option value="NIT">NIT</option>
                                </Field>
                              </InputGroup>
                              <ErrorMessage name="agency.typeDocument">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>

                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-pencil"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" name="agency.identification" autoComplete="off" placeholder="Número Identificación"
                                  value={values.agency.identification}
                                  onChange={handleChange}
                                  onBlur={handleBlur} />
                              </InputGroup>
                              <ErrorMessage name="agency.identification">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>
                          </Row>
                        </div>

                        <div id="secondCamp" className="mt-3">
                          <Row form>
                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-globe-alt"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Field component="select" className="form-control" name="agency.countries"
                                  value={values.agency.countries}
                                  onChange={e => {
                                    handleChange(e);
                                    let index = Number(e.target.value)
                                    console.log(index)
                                    let current;

                                    this.state.countries.forEach(item => {
                                      if (item.id === index)
                                        current = item
                                    })

                                    this.setState({ listCities: current.cities })
                                    if (typeof values.agency.countries === 'number') {
                                      this.setState({ enabledInput: false })
                                    } else if (values.agency.countries === '' || values.client.country === null || values.client.country === undefined) {
                                      this.setState({ enabledInput: true })
                                    }
                                  }}>
                                  <option value="" disabled>Elige tu país</option>
                                  {this.state.countries.map((country, index) => {
                                    return (
                                      <option value={country.id}>{country.name}</option>
                                    );
                                  })}
                                </Field>
                              </InputGroup>
                              <ErrorMessage name="agency.countries">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>

                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-location-pin"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Field component="select" className="form-control" name="agency.cities" disabled={!this.state.enabledInput}
                                  value={values.agency.cities}
                                  onChange={handleChange}>

                                  <option value="" disabled>Elige tu Ciudad</option>
                                  {this.state.listCities.map(city => {
                                    return (
                                      <option value={city.id}>{city.name}</option>
                                    );
                                  })}
                                </Field>
                              </InputGroup>
                              <ErrorMessage name="agency.cities">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>

                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-screen-smartphone"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Field type="text" name="agency.phone" component="input"
                                  render={({ field }) => {
                                    return <MaskedInput
                                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                      {...field}
                                      placeholder="Celular"
                                      guide={false}
                                      autoComplete="off"
                                      className="form-control"
                                      value={values.agency.phone}
                                      onBlur={handleBlur}
                                      onChange={handleChange} />
                                  }} />
                              </InputGroup>
                              <ErrorMessage name="agency.phone">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>
                          </Row>
                        </div>

                        <div id="thirdCamp" className="mt-3">
                          <Row form>
                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-globe"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" name="agency.url" autoComplete="off" placeholder="Pagina Web"
                                  value={values.agency.url}
                                  onChange={handleChange}
                                  onBlur={handleBlur} />
                              </InputGroup>
                              <ErrorMessage name="agency.url">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>

                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-direction"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" name="agency.address" autoComplete="off" placeholder="Dirección"
                                  defaultValue={values.agency.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur} />
                              </InputGroup>
                              <ErrorMessage name="agency.address">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>

                            <Col md={4} className="mb-2">
                              <MySelect
                                value={values.agency.services}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched} />
                            </Col>
                          </Row>
                        </div>

                        <div id="fourCamp" className="mt-3">
                          <Row>
                            <Col md={4} className="mb-2">
                              <Input type="textarea" name="agency.mission" placeholder="Mision" style={{ minHeight: 80, maxHeight: 100 }} maxLength="200"
                                value={values.agency.mission}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            </Col>

                            <Col md={4} className="mb-2">
                              <Input type="file" name="subirLogo" id="subirLogo" />
                              <FormText color="muted"></FormText>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Container>
                </section>

                <section>
                  <Container className="mt-2" >
                    <Card style={{ borderRadius: '15px' }}>
                      <CardBody>

                        {/*Datos del Productor*/}
                        <div>
                          <Label><strong>Registre su Productor</strong></Label>
                          <hr className="my-0" />
                        </div>

                        <div id="datosProduct" className="mt-4">
                          <Row form>
                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-user"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" name="productor.nameProductor" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+" autoComplete="off" placeholder="Nombre de Productor"
                                  value={values.productor.nameProductor}
                                  onChange={e => {
                                    const value = (e.target.validity.valid) ? e.target.value : values.productor.nameProductor
                                    setFieldValue('productor.nameProductor', value)
                                  }}
                                  onBlur={handleBlur} />
                              </InputGroup>
                              <ErrorMessage name="productor.nameProductor">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>

                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-envelope"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input type="email" name="productor.emailProductor" autoComplete="off" placeholder="Correo Electronico"
                                  value={values.productor.emailProductor}
                                  onChange={handleChange}
                                  onBlur={handleBlur} />
                              </InputGroup>
                              <ErrorMessage name="productor.emailProductor">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>

                            <Col md={4} className="mb-2">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-screen-smartphone"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Field type="text" name="productor.phoneProductor" component="input"
                                  render={({ field }) => {
                                    return <MaskedInput
                                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                      {...field}
                                      placeholder="Celular"
                                      guide={false}
                                      autoComplete="off"
                                      className="form-control"
                                      value={values.productor.phoneProductor}
                                      onBlur={handleBlur}
                                      onChange={handleChange} />
                                  }} />
                              </InputGroup>
                              <ErrorMessage name="productor.phoneProductor">
                                {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                              </ErrorMessage>
                            </Col>
                          </Row>

                          <div className="mt-3">
                            <Row>
                              <Col md={4}>
                                <Label style={{ fontSize: '16px' }}>¿Deseas Generar una Contraseña?</Label>
                              </Col>
                              <Col>
                                <div>
                                  <FormGroup check inline style={{ fontSize: 'medium' }}>
                                    <Input
                                      onClick={this.handleCheckGenerateDivPassword}
                                      className="form-check-input"
                                      type="radio" value="radioAutomatic"
                                      checked={this.state.generateFieldPassword === 1 ? false : true}
                                      onChange={handleChange} />
                                    <Label className="form-check-label" check>&nbsp;Automatico</Label>
                                  </FormGroup>

                                  <FormGroup check inline style={{ fontSize: 'medium' }}>
                                    <Input
                                      onClick={this.handleCheckGenerateDivPassword}
                                      className="form-check-input"
                                      type="radio"
                                      value="radioManual"
                                      checked={this.state.generateFieldPassword === 1 ? true : false}
                                      onChange={handleChange} />
                                    <Label className="form-check-label" check>&nbsp;Manual</Label>
                                  </FormGroup>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="mt-3" style={{ display: this.state.generateFieldPassword === 1 ? 'block' : 'none' }} >
                            <Row>
                              <Col md={4} className="mb-2">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-lock"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input type="password" name="productor.password" autoComplete="off" placeholder="Contraseña"
                                    value={values.productor.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                </InputGroup>
                                <ErrorMessage name="productor.password">
                                  {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                                </ErrorMessage>
                              </Col>

                              <Col md={4} className="mb-2">
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-lock"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input type="password" name="productor.verifyPassword" autoComplete="off" placeholder="Confirmar Contraseña"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                </InputGroup>
                                <ErrorMessage name="productor.verifyPassword">
                                  {msg => <div className="animated fadeIn field-error"><small id="emailHelp" className="form-text text-danger"><strong>{msg}</strong></small></div>}
                                </ErrorMessage>
                              </Col>
                            </Row>
                          </div>
                        </div>

                      </CardBody>
                    </Card>

                    <div>
                      <Row>
                        <Col md={12} className="text-center">
                          <button className="btn btn-success mb-4" type="submit" disabled={isSubmitting} size="lg">
                            Enviar Registro
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </section>

                {/* <Modal isOpen={this.state.modal} toggle={isSubmitting} className={this.props.className}> */}
                <Modal isOpen={this.state.modal} className={this.props.className}>
                  {/* <HeaderNotification /> */}

                  <ModalBody>
                    <div className="mt-2">
                      <Label><strong>Bienvenido {values.productor.nameProductor.toUpperCase()}</strong></Label>
                    </div>
                    <div className="mt-3 text-justify">
                      <Label>Ahora tu Agencia será Muy Cool. Revisa tu correo electrónico donde encontrarás los datos de acceso a tu perfil dentro de Talentos.</Label>
                    </div>
                  </ModalBody>

                  <ModalFooter>
                    <Button color="primary" href="/#/login">Aceptar</Button>{' '}
                  </ModalFooter>
                </Modal>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

/*



*/