//Libreries
import React, { Fragment } from 'react';
import { Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import { Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row, Input, Label } from 'reactstrap';
import './FormRegisterFastView.css'

const FormRegisterFastView = props => {
  const {
    values: {
      name,
      lastname,
      email,
      phone,
      typeIdentification,
      identification,
      country,
      city
    },

    types,
    cities,
    countries,
    codeCountry,
    handleChange,
    handleCitiesAndTypes,
    setFieldValue,
    handleBlur,
    changeStateError,
    changeCodeCountry,
    identifiers,
    title,
  } = props;

  return (
    <Fragment>
      <Row className="mt-4">
        <Col>
          <Label className="text-capitalize"><strong>Datos {title === 'productor' ? 'Productor' : 'Agencia'}</strong></Label>
          <hr className="my-0" />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={title === 'productor' ? 6 : 12} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-user"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input type="text"
              name={identifiers.name}
              pattern="^[a-zA-Z ]*$"
              autoComplete="off"
              placeholder={title === 'productor' ? 'Nombre' : 'Nombre Agencia'}
              value={name}
              onChange={e => {
                changeStateError()
                const value = (e.target.validity.valid) ? e.target.value : name
                setFieldValue(identifiers.name, value)
              }}
              onBlur={handleBlur} />
          </InputGroup>
          <ErrorMessage name={identifiers.name}>
            {msg => <div className="delay-2s field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
          </ErrorMessage>
        </Col>

        {title === 'productor' &&
          <Col sm={6} className="mt-2">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input type="text"
                name={identifiers.lastname}
                pattern="^[a-zA-Z ]*$"
                autoComplete="off"
                placeholder="Apellido"
                value={lastname}
                onChange={e => {
                  changeStateError()
                  const value = (e.target.validity.valid) ? e.target.value : lastname
                  setFieldValue(identifiers.lastname, value)
                }}
                onBlur={handleBlur} />
            </InputGroup>
            <ErrorMessage name={identifiers.lastname}>
              {msg => <div className="delay-2s field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
            </ErrorMessage>
          </Col>
        }

        <Col xs={12} sm={6} className="mt-3">
          <FormGroup>
            <div className="page-register-producer-div-country">
              <div className="page-register-producer-logo-country" style={{ padding: codeCountry === '' && '6px' }}>
                <i className={codeCountry === '' ? `icon-globe-alt` : `flag-icon flag-icon-${codeCountry} h4 flag-country-page-register-producer`} title="co" id="co"></i>
              </div>

              <Input type="select" name={identifiers.country} className="page-register-producer-input-country"
                value={country}
                onChange={e => {
                  const code = e.target.value !== '' && countries.find(country => e.target.value === (country.id).toString()).code;
                  console.log("code ==", code);
                  changeCodeCountry(code, title)
                  setFieldValue(identifiers.country, e.target.value)
                  handleCitiesAndTypes(e.target.value, title)
                }} >
                <option value="">País</option>
                {countries.length !== 0 ? countries.map(country => {
                  return (
                    // <option key={countries.id} value={country.id}>{country.name}</option>
                    <option key={country.id} value={country.id}>{country.name}</option>
                  )
                }) : ''}
              </Input>
            </div>
            <ErrorMessage name={identifiers.country}>
              {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
            </ErrorMessage>
          </FormGroup>
        </Col>

        <Col sm={6} className="mt-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-location-pin"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input type="select" name={identifiers.city} placeholder="Ciudad"
              disabled={country === ''}
              value={city}
              onChange={handleChange}>
              <option value="">Ciudad</option>
              {cities.map(item => {
                return <option key={item.id} value={item.id}>{item.name}</option>
              })}
            </Input>
          </InputGroup>
          <ErrorMessage name={identifiers.city}>
            {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
          </ErrorMessage>
        </Col>

        <Col sm={6} >
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-notebook"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input type="select" name={identifiers.typeIdentification}
              disabled={country === ''}
              value={typeIdentification}
              onChange={handleChange} >
              <option value="">Tipo identificación</option>
              {types.map((type, index) => {
                if (title === 'productor' && !type.name.includes('NIT')) {
                  return (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  )
                } else if (title === 'agencia' && type.name.includes('NIT')) {
                  return (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  )
                }
              })}
            </Input>
          </InputGroup>
          <ErrorMessage name={identifiers.typeIdentification}>
            {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
          </ErrorMessage>
        </Col>

        <Col sm={6} >
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-pencil"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input type="text" name={identifiers.identification} value={identification} placeholder="Identificación" onChange={handleChange} />
          </InputGroup>
          <ErrorMessage name={identifiers.identification}>
            {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
          </ErrorMessage>
        </Col>

        {title === 'productor' &&
          <Col sm={12} className="mt-3">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-envelope"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input type="email" name={identifiers.email} autoComplete="off" placeholder="Correo Electrónico"
                value={email}
                onChange={e => {
                  changeStateError()
                  setFieldValue(identifiers.email, e.target.value)
                }}
                onBlur={handleBlur} />
            </InputGroup>
            <ErrorMessage name={identifiers.email}>
              {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
            </ErrorMessage>
          </Col>
        }

        {title === 'productor' &&
          <Col sm={12} className="mt-3">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-screen-smartphone"></i>
                </InputGroupText>
              </InputGroupAddon>
              {/* <Field type="text" name={identifiers.phone} component="input"
                render={({ field }) => {
                  return <MaskedInput
                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    {...field}
                    placeholder="Celular"
                    guide={false}
                    autoComplete="off"
                    className="form-control"
                    name={identifiers.phone}
                    value={phone}
                    onBlur={handleBlur}
                    onChange={e => {
                      changeStateError()
                      setFieldValue(identifiers.phone, e.target.value)
                    }} />
                }} /> */}
                <Field type="text" name={identifiers.phone}
                  render={({ field }) => (
                    <MaskedInput
                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      {...field}
                      placeholder="Celular"
                      guide={false}
                      autoComplete="off"
                      className="form-control"
                      name={identifiers.phone}
                      value={phone}
                      onBlur={handleBlur}
                      onChange={e => {
                        changeStateError()
                        setFieldValue(identifiers.phone, e.target.value)
                      }}
                    />
                  )}
                />
            </InputGroup>
            <ErrorMessage name={identifiers.phone}>
              {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
            </ErrorMessage>
          </Col>
        }
      </Row>
    </Fragment>
  );
}

export default FormRegisterFastView;
