import React, { Component } from 'react';
import { Col, Label, Row, } from 'reactstrap';
import moment from 'moment';
import { configureNameProfileByGender } from '../../../../../../utils/default'
import './Information.css';

class Information extends Component {
  // state = {}
  generateMainProfiles = (profiles) => {    
    let newMainProfiles = profiles.filter(profile => profile?.main === true)
    return newMainProfiles;
  }

  render() {
    let { talent } = this.props;    
    const gender = talent.gender ? talent.gender.toLowerCase() : '';
    var age = talent.birthdate ? moment().diff(talent.birthdate, 'years') : null;

    const validateInformation = talent.height === null && talent.bust === null && talent.waist === null && talent.hip === null && talent.shirt === null && talent.pants === null && talent.footwear === null && talent.residenceCity === null && talent.birthdate === null ? true : false;

    const mainProfiles = this.generateMainProfiles(talent?.profiles?.list)

    return (
      <Row>
        {!validateInformation &&
          <Col md={12} lg={3} className="mt-2">
            <Row className="physical-characteristics">
              {talent.identification &&
                <Col sm={12}>
                  <Label><strong><span>Documento: </span> </strong> {talent.identification}</Label>
                </Col>
              }

              {age &&
                <Col sm={6} lg={12}>
                  <Label><strong>Edad: </strong> {age} años</Label>
                </Col>
              }

              {talent.height &&
                <Col sm={6} lg={12}>
                  <Label><strong>Estatura: </strong> {talent.height}</Label>
                </Col>
              }

              {talent.bust &&
                <Col sm={6} lg={12}>
                  <Label><strong>Busto: </strong> {talent.bust}</Label>
                </Col>
              }

              {talent.waist &&
                <Col sm={6} lg={12}>
                  <Label><strong>Cintura: </strong> {talent.waist}</Label>
                </Col>
              }

              {talent.hip &&
                <Col sm={12}>
                  <Label><strong>Cadera: </strong> {talent.hip}</Label>
                </Col>
              }

              {talent.shirt &&
                <Col sm={12}>
                  <Label><strong>Camisa: </strong> {talent.shirt.toUpperCase()}</Label>
                </Col>
              }

              {talent.pants &&
                <Col sm={12}>
                  <Label><strong>Pantalon: </strong> {talent.pants}</Label>
                </Col>
              }

              {talent.footwear &&
                <Col sm={12}>
                  <Label><strong>Calzado: </strong> {talent.footwear}</Label>
                </Col>
              }

              {talent.residenceCity &&
                <Col sm={12}>
                  <Label><strong><span>Ciudad residencia: </span> </strong> {talent.residenceCity.name}</Label>
                </Col>
              }
            </Row>
          </Col>
        }

        <Col md={12} lg={validateInformation ? 12 : 9} className={'mt-1'} >
          <Label className="title-talent">
            <strong>
              {mainProfiles.map((data, index) => {
                let newProfile = configureNameProfileByGender(gender, data?.profileUserTalent?.profile.toLowerCase()); 
                console.log("newProfileL :", newProfile);
                               
                return `${newProfile}${(index !== mainProfiles.length - 1) ? ', ' : ' '}`
              })}
              {talent.name} {talent.lastname}
            </strong>
          </Label>

          {talent.generalDescription && <p className="text-justify">{talent.generalDescription}</p>}
        </Col>
      </Row >
    )
  }
}

export default Information;