//Libreries
import React, { Component, Fragment } from 'react';
import { Label } from 'reactstrap';

class Experience extends Component {
  state = {}

  render() {
    const { historical } = this.props;
    return (
      <Fragment>
        {historical.length !== 0 ? 
          historical.map(element => {
            return (
              <div key={element.id}>
                <Label> <strong>{element.area.name || ''}:&nbsp;</strong> </Label>

                <Label>{element.description}</Label>
              </div>
            )
          })
          :
          <div className="text-center">No tiene ninguna experiencia</div>
        }
      </Fragment>
    )
  }
}

export default Experience;
