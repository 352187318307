import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Label, FormGroup, Input } from 'reactstrap';

class ModalChangeTypePhoto extends Component {

    state = {
        selectedOption: this.props.imageSelecte.type,
        changed: false
    };

    handleOptionChange = (event) => {
        const { value } = event.target;
        this.setState({
            selectedOption: value,
            changed: value !== this.props.imageSelecte.type // Verificar si la opción seleccionada es diferente a la opción por defecto
        });
    };

    render() {
        const { handleChange, isOpen, handleCloseModal, indexSelect, imageSelecte } = this.props;
        const { selectedOption, changed } = this.state;

        return (
            <Fragment>
                <Modal isOpen={isOpen} fullscreen={'sm'} >
                    <ModalHeader>
                        Cambiar de categoría
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup tag="fieldset">
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="option"
                                        value="IBOOK"
                                        checked={selectedOption === 'IBOOK'}
                                        onChange={this.handleOptionChange}
                                    />
                                    IBOOK
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="option"
                                        value="GALLERY"
                                        checked={selectedOption === 'GALLERY'}
                                        onChange={this.handleOptionChange}
                                    />
                                    GALLERY
                                </Label>
                            </FormGroup>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter style={{ display: 'flex !impotant', alignItems: 'center !important' }}>
                        <Button
                            color="secondary"
                            onClick={handleCloseModal}
                            style={{ cursor: 'pointer' }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            color={'success'}
                            style={{ cursor: (!selectedOption || !changed) ? 'not-allowed' : 'pointer' }} // Se deshabilita si no se ha cambiado
                            disabled={!selectedOption || !changed} // Se deshabilita si no se ha cambiado
                            onClick={() => handleChange(selectedOption, indexSelect)}
                        >
                            Aceptar
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }

}

export default ModalChangeTypePhoto;