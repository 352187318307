import React, { Component, Fragment } from 'react';
import { Col, Label, Row } from 'reactstrap';

class Formation extends Component {
  render() {
    const { academicTrainings } = this.props

    return (
      <Fragment>
        {academicTrainings && academicTrainings.length !== 0 ? (
          academicTrainings.map(skill => {
            return (
              <div key={skill.node.id}>
                {
                  (skill.node.skill.areaSkills.nodes[0].areaId == 8) &&

                  <Row>
                    <Col size={6}>
                      <Label>
                        <strong>Institución: </strong> {skill.node.observations}
                      </Label>
                    </Col>
                    <Col size={6}>
                      <Label>
                        <strong>Título: </strong> {skill.node.skill.name}
                      </Label>
                    </Col>
                  </Row>
                }
                {
                  skill.node.skill.areaSkills.nodes[0].areaId == 1 &&
                  <Row>
                    <Col size={6}>
                      <Label>
                        <strong>Idioma: </strong> {skill.node.skill.name}
                      </Label>
                    </Col>
                    <Col size={6}>
                      <Label>
                        <strong>Nivel: </strong> {skill.node.level.name}
                      </Label>
                    </Col>
                  </Row>
                }
              </div>
            );
          })
        ) : (
          <div className="text-center">No tiene ninguna aptitud</div>
        )}
      </Fragment>
    );
  }
}

export default Formation;
