import React, { Component, Fragment } from 'react';
import { withApollo } from 'react-apollo';
import ApplyAgentView from './ApplyAgentView'
import {
  GET_EVENTS_BY_AGENCY_APPLY_AGENT,
  GET_DATA_FOR_APPLY_AGENCY
} from '../../../../controllers/graphql/queries';
import ServiceInteractor from '../../../../controllers/services/ServiceInteractor'

const Interactor = new ServiceInteractor();

class ApplyAgent extends Component {
  state = {
    events: [],
    type: 0,
    loading: false,
    error: false,
    showInputPhone: false,
    status: false,
    isOpenAlert: false
  }

  componentDidMount() {
    this.getEvents()
  }

  orderEvent = async () => {
    const { client } = this.props;
    const { agency } = JSON.parse(localStorage.getItem('account'));

    try {
      const { data } = await client.query({
        query: GET_EVENTS_BY_AGENCY_APPLY_AGENT,
        variables: { id: agency }
      });

      const arreglo = data.agency?.quotations?.nodes || [];
      let newList = [];

      for (let index = 0; index < arreglo.length; index++) {
        if (arreglo[index].events.nodes.length > 0) {
          newList.push(arreglo[index].events.nodes[0]);
        }
      }

      return newList

    } catch (error) {
      console.log("error: ", error);
    }

  }

  getEvents = async () => {
    let array = [];
    let events= await this.orderEvent();   

    events.forEach(event => {
      let object = {
        value: event.id,
        label: `${event.name} - ${event.quotation?.code || '##'}`
      }
      array.push(object)
    })
    this.setState({ events: array })
  }

  handleActions = (type, event) => {
    const { isOpenModal } = this.props;
    const { showInputPhone } = this.state;
    const account = JSON.parse(localStorage.getItem('account'));
    this.setState({ type })
    if (type === 1) {
      this.setState({ showInputPhone: !this.state.showInputPhone })
    } else {
      if (type === 3) {
        const url = `https://wa.me/573004701318?text=Hola,%20soy%20${account.nameUser}%20${account.lastnameUser}%20deseo%20solicitar%20Soporte%20Técnico%20para%20m%C3%AD%20evento%20${event.label}.`
        window.open(url);
        isOpenModal()
      }

      showInputPhone && this.setState({ showInputPhone: false })
    }
  }

  schedule = async values => {
    const { phone, event } = values;
    this.setState({ loading: true })
    const { client } = this.props
    const { id } = JSON.parse(localStorage.getItem('account'))

    try {
      const { data } = await client.query({
        query: GET_DATA_FOR_APPLY_AGENCY,
        variables: { id }
      })

      let object = {
        agency: data.user.agencies.list[0].name,
        event: event === '' ? null : event,
        user: {
          email: data.user.email,
          name: data.user.name,
          lastname: data.user.lastname,
          phone: phone.replace(/[()' '-]/g, ''),
          legalNature: data.user.agencies.list[0].legalNature.includes('NATURAL') ? 'persona' : 'comercio'
        },
      }

      await Interactor.postApplyAgentSchedule(object)
      this.setState({ loading: false, isOpenAlert: true, })

    } catch (error) {
      console.log(error)
      this.setState({ loading: false, error: true })
    }
  }

  dismissing = () => this.setState({ isOpenAlert: false })

  render() {
    return (
      <Fragment>
        <ApplyAgentView
          {...this.props}
          {...this.state}
          handleActions={this.handleActions}
          schedule={this.schedule}
          dismissing={this.dismissing} />
      </Fragment>
    )
  }
}

export default withApollo(ApplyAgent);
