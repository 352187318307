import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import ForgotPasswordView from './ForgotPasswordView';
import ServiceInteractor from '../../../controllers/services/ServiceInteractor'
import { getEmailForgotPassword, getEmailForgotPasswordTalent } from '../../../controllers/graphql/queries';
import queryString from 'query-string';

const Interactor = new ServiceInteractor()

class ForgotPassword extends Component {
  state = {
    loading: false,
    error: false,
    success: false,
    typeParam: false,
    initialValues: {}
  }

  componentWillMount() {
    const queryParams = queryString.parse(this.props.location.search);
    const typeParam = queryParams.select || false;
    this.setState({ typeParam });
    this.handleInitialValues(typeParam);
  }

  handleInitialValues = (typeParam) => {
    console.log("typeParam", typeParam);
    let initialValues = {}

    if (typeParam && typeParam == 'talent') {
      initialValues = {
        email: '',
        agencia: false,
        talento: true
      }
    } else {
      initialValues = {
        email: '',
        agencia: true,
        talento: false
      }
    }
    // console.log("initialValues", initialValues);
    this.setState({ initialValues });
  }

  searchEmail = values => {
    this.setState({ loading: true })
    const { client } = this.props;

    return new Promise(async (resolve, reject) => {
      try {
        if (values.agencia) {
          const { data } = await client.query({
            query: getEmailForgotPassword,
            variables: { email: values.email.trim().toLowerCase() }
          });
          // console.log("data", data);
          await this.sendMailRestorePassword(data, values);

        } else {
          const { data } = await client.query({
            query: getEmailForgotPasswordTalent,
            variables: { email: values.email.trim().toLowerCase() }
          });
          // console.log("data", data);
          await this.sendMailRestorePassword(data, values);
          window.location.href = 'https://niriun.com/niriun-talents/#descarga';
        }
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }

  sendMailRestorePassword = async (data, values) => {
    if (data.users?.list.length === 0 || data.talents?.list.length === 0) {
      this.setState({
        loading: false,
        error: 'Este correo no se encontro dentro de la plataforma'
      })
    } else {
      let object = {
        id: data.users?.list[0].id || data.talents?.list[0].id,
        email: values.email,
        name: data.users?.list[0].name || data.talents?.list[0].name,
        lastname: data.users?.list[0].lastname || data.talents?.list[0].lastname,
        type: data.users ? 'agency' : 'talent'
      }
      await Interactor.postNotifyForgotPassword(object);
      this.setState({ success: true, loading: false })
    }
  }

  handleChangeError = () => this.setState({ error: false })

  render() {
    const { loading, error, success, typeParam, initialValues } = this.state
    return (
      <ForgotPasswordView
        loading={loading}
        error={error}
        success={success}
        typeParam={typeParam}
        searchEmail={this.searchEmail}
        handleChangeError={this.handleChangeError}
        initialValues={initialValues} />
    )
  }
}

export default withApollo(ForgotPassword);
