import React, { Fragment } from 'react';
import { Col, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import italentt from '../../assets/img/brand/logo-blanco-niriun.png';

class Header extends React.Component {
  state = {
    isOpen: false
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const { isOpen } = this.state;
    const { match: { path } } = this.props;
    return (
      <Fragment>
        <Navbar light expand="md" style={{ backgroundColor: 'rgb(0,0,0)' }} className="d-flex justify-content-between">
          <Col>
            <NavbarBrand className="text-white" href="https://niriun.com/niriun-business/">
              <img src={italentt} alt="iTalentt" title="iTalentt" style={{ width: '10rem' }} />
            </NavbarBrand>
          </Col>
          <NavbarToggler onClick={this.toggle} style={{ background: 'rgb(255, 255, 255)' }} />
          <Collapse isOpen={isOpen} className="text-white" navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                {path.includes('registrofast') && (
                  <Link to="/login" className="text-white mr-5">
                    <h5 style={{ display: 'inline-block' }}>Iniciar Sesión</h5>
                  </Link>
                )}
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Fragment>
    );
  }
}

export default withRouter(Header)
